import { Document, Font, Page, Text, View } from "@react-pdf/renderer"
import { DevisInfos } from "../../../class/devis";
import { PDFDevisSinature } from "./pdf-devis-signature";
import { PDFDevisMontantTotal } from "./pdf-devis-montant-total";
import { PDFHeader } from "./pdf-header";
import { PDFLigneTitre } from "./pdf-ligne-titre";
import { PDFLigneArticle } from "./pdf-ligne-article";
import { PDFLigneCommentaire } from "./pdf-ligne-commentaire";
import { PDFFooter } from "./pdf-footer";
import { PDFBodyHeader } from "./pdf-body-header";
import { getDevisClientModelStyles } from "./devis-client-model-style";
import { PDFLogo } from "./pdf-logo";


export interface DevisClientModel {
    devisInfos: DevisInfos;
    infosPDF?: InfosPDF | undefined;
    isProForma?: boolean;
    hidePrice?: boolean;
    hideTotalDevis?: boolean;
    hideAdresse?: boolean;
    devisWithoutSinistre?: boolean;
    code_agence?: number;
    pageNumber?: number;
}

export interface InfosPDF {
    client: { fax: string };
    photos: { [key: string]: string };
}

export const DevisClientModel = ({ pageNumber, devisInfos, isProForma, hidePrice, hideTotalDevis, hideAdresse, devisWithoutSinistre }: DevisClientModel) => {
    const styles = getDevisClientModelStyles({ isProForma });

    Font.register({
        family: 'Open Sans',
        fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 'bold' },
        ]
    });

    return (
        <Document title='Devis'>
            <Page style={styles.page}>
                <PDFLogo devisInfos={devisInfos} pageNumber={pageNumber} />
                <PDFHeader devisInfos={devisInfos} isProForma={isProForma} hideAdresse={hideAdresse} devisWithoutSinistre={devisWithoutSinistre} />
                <View>
                    <PDFBodyHeader hidePrice={hidePrice} />
                    <View>
                        {devisInfos.lignes.map(ligne => {
                            return (
                                <View key={ligne.id} wrap={false}>
                                    <View >
                                        {ligne.is_titre ? <PDFLigneTitre text={ligne.titre.content} montant_total={ligne.montant_total} hidePrice={hidePrice} typeTitre={ligne.titre.type} />
                                            : (ligne.is_article || ligne.is_lot) ? <PDFLigneArticle ligne={ligne} hidePrice={hidePrice} />
                                                : ligne.is_nota ? <PDFLigneCommentaire text={ligne.commentaire} />
                                                    : <Text></Text>}
                                    </View>

                                </View>
                            )
                        })}
                    </View>

                </View>
                <View style={styles.containerSignatureAndCalculs}>
                    {!isProForma && <PDFDevisSinature />}
                    {!hidePrice && !hideTotalDevis && <PDFDevisMontantTotal devisInfos={devisInfos} />}
                </View>
                <View style={styles.textValidation}>
                    <Text>Validité de 1 mois à compter de la date du devis.</Text>
                    <Text>Toute validation postérieure au délai sera soumise à analyse avant confirmation de la tarification.</Text>
                </View>
                <PDFFooter />
            </Page>
        </Document>
    )

};

export const DevisClientModelClassic = (props: any) => DevisClientModel({ ...props });
export const DevisClientModelClassicProformat = (props: any) => DevisClientModel({ ...props, isProForma: true });
export const DevisClientModelSansPrix = (props: any) => DevisClientModel({ ...props, hidePrice: true, hideTotalDevis: true });
export const DevisClientModelSansPrixSansAdresse = (props: any) => DevisClientModel({ ...props, hidePrice: true, hideTotalDevis: true, hideAdresse: true });
export const DevisClientModelChiffreSansSinistre = (props: any) => DevisClientModel({ ...props,  devisWithoutSinistre: true});

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

export const aaa = ''; // Ne pas supprimer, permet le live reload de react