import BtnTooltips from "../../tools/btn-tooltips";
import formatDate from "../../functions/formatDate";
import { useNavigate } from "react-router-dom";
import { Devis } from "../../../class/devis";
import formatMontant from "../../functions/formatMontant";
import { useState } from "react";
import DevisService from "../../../services/DevisService";
import ConfirmModal from "../../tools/confirm-modal";
import { formatUrl } from "../../functions/formatUrl";
import { DocumentDivalto, corresp_statuts_devis } from "../../../class/document";
import ScreenSizeComponent from "../../functions/screen-size-component";
import { Icon } from "../../tools/icon";

type ItemDevisProps = {
    isAssistante: boolean;
    devis: Devis; // recupère le string lors de l'appel à la fonction
    refreshListeDevis: () => void;
    showDupplicateModal: (doc: DocumentDivalto) => void;
};

const ItemDevis = ({ isAssistante, devis, refreshListeDevis, showDupplicateModal }: ItemDevisProps) => {

    const [isLoading, setIsLoading] = useState(false);
    const [showModalSend, setShowModalSend] = useState(false);
    const navigate = useNavigate();

    const voirLeDevis = (e: any) => {
        e?.preventDefault();
        devis.divalto
            ? navigate('/devis/divalto/' + devis.id)
            : navigate('/devis/local/' + devis.id);
    }

    function sendDevis() {
        setIsLoading(true);
        //setMessageModal(<Loadering color={'bleu-actif'} formodal={true} />);
        DevisService.transferDevis(devis.id)
            .then(response => {
                response.data?.status === 'ok'
                    ? refreshListeDevis()
                    : console.log(response.data);
                setIsLoading(false);
            })
            .catch(error => console.log(error));
    }

    const voirSociete = (e: any) => {
        e?.preventDefault();
        navigate(formatUrl('affaire', devis.affaire_id));
    }

    const handleCancel = () => setShowModalSend(false);
    const handleConfirm = () => {
        sendDevis();
    }
    const windowWidth = ScreenSizeComponent();
    const messageSend = <>Vous allez envoyer le devis "{devis.num_piece}".<br /> Vous ne pourrez plus modifier ce devis depuis ce site.</>;

    return (
        <div className="lignevert position-relative container-fluid" id={devis.id + ''}>
            <div className="row align-items-center ">
                <div className="col-3 col-md-2 display-16 font-bold dating text-center bg-bleu-clair2 ms-0">
                    <div className="pt-3 pb-2">{formatDate(devis.createDate, true, true, false)}</div>
                </div>

                <div className="col-9 col-md-10 bg-white rounded py-3 mb-2 card-hover position-relative p-0">
                    <div className="container-fluid">
                        <div className="row justify-content-between align-items-center ">
                            <div className="col-md-3 col-xxl-5 d-md-flex align-items-center justify-content-start">
                                {/* {devis.divalto && */}
                                <BtnTooltips tooltiplabel={'Dupliquer le devis'}
                                    onClick={() => showDupplicateModal(devis)}
                                    buttonstyle={'nobt bleu-actif fa fa-copy me-3 btn-dupliquer display-11'} />
                                {/* } */}
                                <div className=" d-flex align-items-start justify-content-start flex-column">
                                    <div className="d-flex align-items-start ">
                                        <div className="d-block d-xxl-flex align-items-center">
                                            <a href="" onClick={voirLeDevis} className="font-bold display-15 bleu-actif ln-1 d-block d-xxl-flex">
                                                {devis.reference && devis.num_piece && <>
                                                    <span className="font-bold display-15 me-1">{devis.num_piece}</span>
                                                    <span className="mx-1 d-none d-xxl-block">|</span>
                                                </>}
                                                <span>
                                                    {devis.reference || devis.num_piece}
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="text-uppercase mt-1">
                                        <span>
                                            {devis.nom_assure}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-xxl-3">
                                <div className="d-flex align-items-start justify-content-start flex-column">
                                    <a href="" onClick={voirSociete} className="nobt bleu mb-1"  >
                                        Affaire :<span className="font-bold ms-2 text-uppercase">{devis.numero_dossier}</span>
                                    </a>
                                    <div>
                                        Heures prévues : <span className="font-bold">{devis.budget_heures && devis.budget_heures !== ".00" ? devis.budget_heures.replace('.', ',') + " h" : "0 h"}</span>
                                    </div>
                                    {isAssistante &&
                                        <div className="nobt bleu mb-1">
                                            Agence : <span className="font-bold ms-2 text-uppercase">{devis.code_agence}</span>
                                        </div>
                                    }
                                    <div className="violet">
                                        Visite le <span className="font-bold text-uppercase">{devis.date_visite.getFullYear() === 1970 ? '...' : devis.date_visite?.toLocaleDateString()}</span>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-2 col-xxl-2">
                                {
                                    <div className="fst-italic mt-1 mt-md-0">
                                        <span className="font-bold violet3">
                                            {devis.statut === '1' ? "En cours" : corresp_statuts_devis[devis.statut]}
                                            <Icon icon={`${devis.statut === '1' ? ''
                                                : devis.statut === '2' ? 'check'
                                                    : devis.statut === '3' ? 'ban'
                                                        : devis.statut === '4' ? 'ban'
                                                            : devis.statut === '5' ? 'circle-question'
                                                                //    : devis.statut === '6' ? 'arrow-right'
                                                                : devis.statut === '7' ? 'ellipsis'
                                                                    //    : devis.statut === '8' ? 'triangle-exclamation'
                                                                    : ''
                                                } mx-2`} />
                                        </span>
                                        { // "2" => devis accepté
                                            devis.statut === "2" && <>
                                                <br />
                                                le <span className="text-uppercase">{devis.date_accord?.toLocaleDateString()}</span>
                                            </>
                                        }
                                        { // "7" => devis envoyé
                                            devis.statut === "7" && <>
                                                <br />
                                                le <span className="text-uppercase">{devis.date_envoi?.toLocaleDateString()}</span>
                                            </>
                                        }
                                    </div>
                                }
                                {/*!devis.divalto &&
                                
                                    <button className="btn btn-orange" onClick={() => setShowModalSend(true)}>
                                        Transférer sur Divalto
                                    </button>*/
                                }

                            </div>
                            <div className="col-md-4 col-xxl-2 d-flex align-items-center justify-content-end">
                                <div className="display-15 font-bold montant nowrap me-0">{formatMontant(devis.montant, true)} <span className="display-18 font-normal">HT</span></div>
                                {devis.divalto
                                    ? <BtnTooltips
                                        onClick={voirLeDevis}
                                        tooltiplabel={'Voir le devis'}
                                        buttonstyle={'nobt fa fa-eye bleu-actif ms-4  btn-voir display-12'}
                                        key={devis.id} />
                                    : <BtnTooltips tooltiplabel={'Éditer le devis'}
                                        onClick={voirLeDevis}
                                        key={devis.id}
                                        buttonstyle={'nobt fa fa-pencil bleu-actif ms-4  btn-editer display-12'} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmModal
                show={!!showModalSend}
                title={'Attention'}
                message={messageSend}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                isRed={false}
                isLoading={isLoading} />
        </div>
    )

}
export default ItemDevis