import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react"
import { SearchTableControlled } from "../dataTable/SearchTable"
import Switcher from "../../tools/switcher"
import { Agency } from "../../../class/agency"
import { Utilisateur, assistante } from "../../../class/utilisateur"
import { allAgencies } from "./affaires-wrapper"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Loadering } from "../../tools/loadering"

interface AffaireListeHeaderProps {
    setFilterText: Dispatch<SetStateAction<string>>;
    selectMyAffaires: () => void;
    agencies: Agency[] | undefined;
    toggleClassSearch: string;
    setAgency: Dispatch<SetStateAction<string>>;
    user: Utilisateur,
    myAffaires: boolean,
    isNotFinished: boolean,
    agency: string,
    filterText: string,
    getAffairesByAgency: (agencyCode: string) => void
}

export const AffairesListeHeader = ({ setFilterText, selectMyAffaires, myAffaires, agencies, toggleClassSearch, setAgency, user, isNotFinished, agency, filterText, getAffairesByAgency }: AffaireListeHeaderProps) => {
    const [listFiltred, setListFiltred] = useState(false);

    const changeAgency = (e: ChangeEvent<HTMLSelectElement>) => {
        isNotFinished && getAffairesByAgency(e.target.value);
        (isNotFinished && e.target.value !== allAgencies) 
            ? setListFiltred(true)
            : setListFiltred(false)
        setAgency(e.target.value);
    }

    const navigate = useNavigate();
    const [queryParameters] = useSearchParams();
    const querySearch = queryParameters.get('search');

    useEffect(() => {
        agency && getAffairesByAgency(agency)
    }, [])

    useEffect(() => {
        const queryParams = {
            search: filterText,
            agency: agency
        };

        navigate({
            search: new URLSearchParams(queryParams).toString(),
        });
    }, [filterText, agency])

    return (
        <div className={`d-md-flex align-items-center justify-content-between p-0 ${toggleClassSearch} w-100 ps-md-2`}>
            <div className="entete row">
                {user?.type_employe === assistante ?
                    <div className="col-md-8 d-flex flex-row align-items-center w-100">
                        <div className="col-md-3 font-bold display-17 bleu filter_agence_code" >Agence | Code :</div>
                        <select
                            className="form-select w-75"
                            id="pourcent2"
                            onChange={(e) => changeAgency(e)}
                            value={agency}>
                            <option value={allAgencies}>Toutes les agences</option>
                            {agencies?.map(agency =>
                                <option key={agency.agency_code} value={agency.agency_code}>{agency.agency_name} | {agency.agency_code}</option>
                            )}
                        </select>
                    </div>
                    : <Switcher label={'Mes affaires'}
                        onChange={selectMyAffaires}
                        isChecked={myAffaires}
                    />
                }
            </div>
            <div className="d-flex justify-content-end pb-1 pb-md-0 ms-auto">
                {isNotFinished && !listFiltred
                    ? <Loadering color={'bleu'} formodal={true} />
                    : <></>
                }
                <SearchTableControlled setFilterText={setFilterText} value={querySearch || ''} />
            </div>
        </div>
    )
}