import React, { ChangeEvent } from "react";
import { TemplateMail, templateEmailId } from "../../../class/templateMail";

interface SelectedEmailModelProps {
    handleSelectEmailModelId: (e: ChangeEvent<HTMLSelectElement>) => void;
    emailModelId: string;
    templateMails: TemplateMail[];
}

export const SelectEmailModel = ({ handleSelectEmailModelId, emailModelId, templateMails }: SelectedEmailModelProps) => {

    const filteredTemplatesEmail = templateMails.filter(template => templateEmailId.includes(template.id.toString()));

    return (
        <select className="form-select bg-bleu-clair1 border-0 bleu" onChange={handleSelectEmailModelId} value={emailModelId}>
            {filteredTemplatesEmail?.map((template) => (
                <option key={template.id} value={template.id}>
                    {template.name}
                </option>
            ))}
        </select>
    );
};
