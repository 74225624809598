import { StyleSheet, Text, View } from "@react-pdf/renderer"
import { DevisInfos } from "../../../class/devis";
import { getDevisTotaux } from "../../../components/forms/editiondevis/devis-body/devis-body-totaux";
import formatMontant, { formatPourcent, formatPrice, formatPriceToNumber } from "../../../components/functions/formatMontant";

export interface PDFDevisMontantTotauxProps {
    devisInfos: DevisInfos;
}

export const PDFDevisMontantTotal = ({ devisInfos }: PDFDevisMontantTotauxProps) => {
    const styles = getPdfDevisMontantTotalStyle();

    const { sousTotalHT, remiseGlobalHT, sousTotalHTApRemise, totalTTC, tauxTVAListe, tauxTVAListeApRemise, totalTVA } = getDevisTotaux(devisInfos);

    // ⚠️ Vérifier que ce sont les bons champs 
    return (
        <View style={styles.containerMontant}>
            <View style={styles.calculsDevis}>
                <View style={styles.colonneCalculs}>
                    <Text style={{ paddingBottom: '10px' }}>Régime TVA</Text>
                    {tauxTVAListe.map((tva, index) => (
                        <Text key={index} style={{ alignSelf: 'flex-start' }}>{index + 1}</Text>
                    ))}
                </View>
                <View style={styles.colonneCalculs}>
                    <Text style={{ paddingBottom: '10px' }}>Base TVA</Text>
                    {tauxTVAListeApRemise.map((tva, index) => (
                        <Text key={index}>{formatMontant(tva.subTotal)}</Text>
                    ))}
                </View>
                <View style={styles.colonneCalculs}>
                    <Text style={{ paddingBottom: '10px' }}>Taux</Text>
                    {tauxTVAListe.map((tva, index) => (
                        <Text key={index}>{formatPourcent(tva.tva)} %</Text>
                    ))}
                </View>
                <View style={styles.colonneCalculs}>
                    <Text style={{ paddingBottom: '10px' }}>Montant</Text>
                    {tauxTVAListeApRemise.map((tva, index) => (
                        <Text key={index}>{formatMontant(tva.tvaAmount)}</Text>
                    ))}
                </View>
            </View>
            <View style={styles.containerTotalDevis}>
                <View style={styles.lignesTotalDevis}>
                    <View style={styles.ligneTotalDevis}>
                        <Text>TOTAL HT</Text>
                        <Text>{formatPrice(sousTotalHT)} EUR</Text>
                    </View>
                    <View style={styles.ligneTotalDevis}>
                        <Text>Remise {formatPourcent(formatPriceToNumber(devisInfos.remise_global) || 0)} %</Text>
                        <Text>{formatPrice(remiseGlobalHT)} EUR</Text>
                    </View >
                    <View style={styles.ligneTotalDevis}>
                        <Text>TOTAL HT Remisé </Text>
                        <Text> {formatPrice(sousTotalHTApRemise)} EUR</Text>
                    </View>

                </View>
                <Text style={styles.ligne}></Text>
                <View style={styles.ligneTotalDevis}>
                    <Text style={{ fontWeight: "black", fontSize: 10 }}>TOTAL T.T.C</Text>
                    <Text style={{ fontWeight: "black", fontSize: 10 }}>*****{formatPrice(totalTTC)} EUR</Text>
                </View>
            </View>
        </View>
    )
}

export const getPdfDevisMontantTotalStyle = () => {
    return StyleSheet.create({
        containerMontant: {
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            gap: 5,

        },
        // ---------------Style Caluls devis
        calculsDevis: {
            border: '1px solid "#e3e3e3"',
            padding: 3,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            gap: 8
        },
        colonneCalculs: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
        },

        // ---------------Style total devis
        containerTotalDevis: {
            display: 'flex',
            flexDirection: 'column',
            gap: 4
        },
        lignesTotalDevis: {
            display: 'flex',
            flexDirection: 'column',
            gap: 4
        },
        ligneTotalDevis: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: 30
        },
        ligne: {
            width: '100%',
            border: '1px solid "#e3e3e3"',
        }
    })
}