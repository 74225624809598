import React, { useEffect, useState, ReactNode } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { models, IEmbedConfiguration } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { API } from '../Constantes';
import authHeader from '../services/AuthHeader';
import { Loadering } from '../components/tools/loadering';
import BlocRapport from '../components/forms/reporting/BlocRapport';
import Header from '../components/header/header';
import FullscreenButton from '../components/tools/full-screen-button';
import './reporting.css';

const Reporting = () => {
    type ReportItem = {
        nom: string;
        description: string;
        groupId: string;
        reportId: string;
        datasetId: string;
        filtre: string;
    };

    const { reportId: initialReportId } = useParams();

    const [forceRender, setForceRender] = useState(0);
    const [BlocsRapportData, setBlocsRapportData] = useState<ReportItem[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [reloadKey, setReloadKey] = useState<number>(0);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [embedInstances, setEmbedInstances] = useState<{ [key: string]: ReactNode }>({});
    const [selectedReport, setSelectedReport] = useState<any>(null);

    const getEmbedInstance = (report: any, accessToken: string) => {
        return (
            <PowerBIEmbed
                key={report.reportId}
                embedConfig={{
                    type: 'report',
                    id: report.reportId,
                    accessToken: accessToken,
                    tokenType: models.TokenType.Embed,
                }}
                cssClassName={`report-style-class ${isFullscreen ? 'fullscreen' : ''}`}
            />
        );
    };

    const fetchAccessTokenAndLoadReport = async (report: any) => {
        if (embedInstances[report.reportId]) {
            setSelectedReport(report);
            return;
        }

        try {
            const response = await axios.get(API + 'powerbi/access-token', {
                headers: authHeader(),
                params: {
                    groupId: report.groupId,
                    reportId: report.reportId,
                    datasetId: report.datasetId,
                    filtre: report.filtre
                },
            });

            const token = response.data.access_token;
            const newEmbedInstance = getEmbedInstance(report, token);
            setEmbedInstances(prev => ({ ...prev, [report.reportId]: newEmbedInstance }));
            setSelectedReport(report);
        } catch (error: any) {
            console.error('Error fetching access token:', error);
            setError("Erreur lors de la récupération du token, veuillez réessayer et contacter un administrateur si cela se reproduit.");
        } finally {
            setIsLoading(false);
        }
    };

    const toggleFullscreen = () => {
        const element = document.getElementsByClassName('fullscreen-container')[0] as HTMLElement;
        if (!isFullscreen) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            }
        } else {
            if (document.exitFullscreen && document.fullscreenElement) {
                document.exitFullscreen();
            }
        }
        setIsFullscreen(prevFullscreenState => !prevFullscreenState);
    };

    useEffect(() => {
        const fetchReports = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(API + 'powerbi/get_powerbi_reports', {
                    headers: authHeader()
                });
                const data = response.data;
                setBlocsRapportData(response.data as ReportItem[]);
                const initialReport = data.find((report: any) => report.reportId === initialReportId) || data[0] || null;
                setSelectedReport(initialReport);
                if (initialReport) {
                    fetchAccessTokenAndLoadReport(initialReport);
                }
            } catch (error) {
                console.error('Error fetching reports:', error);
                setError('Error fetching reports');
            } finally {
                setIsLoading(false);
            }
        };

        fetchReports();
    }, []);

    useEffect(() => {
        if (selectedReport) {
            setError(null);
            fetchAccessTokenAndLoadReport(selectedReport);
        }
    }, [selectedReport]);

    const handleReloadReporting = async (report: any) => {
        setIsLoading(true);
        setError(null);

        if (embedInstances[report.reportId]) {
            if (selectedReport?.reportId !== report.reportId) {
                setSelectedReport(report);
            } else {
                setSelectedReport({ ...report });
            }
            setForceRender(prev => prev + 1);
            setIsLoading(false);
        } else {
            await fetchAccessTokenAndLoadReport(report);
        }
    };

    return (
        <div className="content-wrapper w-100">
            <Header titre={'Reporting'} icone={'chart-line'} infos={false} recherche={false} />
            <main className="bg-bleu-clair1 h-100 reporting" key={reloadKey}>
                <div className="entete">
                    <div className={`scrollbar height-${BlocsRapportData.length < 3 ? '16' : '14'} bg-creme shadow-bottom`}>
                        <div className={`scrollbar height-${BlocsRapportData.length < 3 ? '16' : '14'} bg-creme shadow-bottom`}>
                            <div className="d-flex align-items-center h-100 w-100">
                                <div className="my-auto container-fluid">
                                    <div className="row">
                                        {BlocsRapportData.map((report: ReportItem, index: number) => (
                                            <BlocRapport
                                                key={index}
                                                nom={report.nom}
                                                description={report.description}
                                                reportId={report.reportId}
                                                active={selectedReport?.reportId === report.reportId}
                                                onReportClick={() => handleReloadReporting(report)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`scrollbar height-${BlocsRapportData.length < 3 ? '17' : '15'} `}>
                        {isLoading ? (
                            <Loadering color={'bleu'} />
                        ) : (
                            <div className="mt-2 mx-2 position-relative">
                                {error ? (
                                    <div className="d-flex align-items-center h-100 justify-content-center">
                                        <div className="text-center">
                                            <span className='d-block mb-1 display-3 ln-1'>
                                                <span className="fa fa-ban font-bold rouge d-block m-auto"></span>
                                            </span>
                                            <span className="font-bold">
                                                {error || "Une erreur inconnue s'est produite, veuillez contacter un administrateur."}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={`fullscreen-container report-style-class ${isFullscreen ? 'fullscreen' : ''}`} key={forceRender}>
                                        <FullscreenButton
                                            isFullscreen={isFullscreen}
                                            toggleFullscreen={toggleFullscreen}
                                        />
                                        {selectedReport && embedInstances[selectedReport.reportId] ? (
                                            embedInstances[selectedReport.reportId]
                                        ) : (
                                            <Loadering color={'bleu'} />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </div>
    );
};
export default Reporting;