import { Affaire } from "../../../class/affaire";
import { DestinataireProps } from "../../../class/templateMail";


interface SelectDestinatairesProps {
    affaire?: Affaire;
    handleSelectValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
    selectValueInput: string;
    selectDestinataire: string;
    handleSelectDestinataire: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    destinairePresentInAffaire: DestinataireProps[];
};

export const SelectDestinataire = ({ handleSelectValue, selectValueInput, handleSelectDestinataire, selectDestinataire, destinairePresentInAffaire }: SelectDestinatairesProps) => {

    return (
        <>
            <select className="form-select bg-bleu-clair1 border-0 bleu" onChange={handleSelectDestinataire} value={selectDestinataire}>
                <option value="">Choisissez votre destinataire</option>
                {destinairePresentInAffaire?.map((dest) => (
                    <option key={dest.id} value={dest.label}>
                        {dest.label}
                    </option>
                ))}
            </select>
            <input
                className="form-control"
                placeholder={"A :"}
                value={
                    selectDestinataire !== ''
                        ? destinairePresentInAffaire.find(dest => dest.label === selectDestinataire)?.email || ''
                        : selectValueInput
                }
                onChange={selectDestinataire === '' ? handleSelectValue : undefined}
            />
        </>
    );
};
