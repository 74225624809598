import { StyleSheet, Text, View } from "@react-pdf/renderer"
import { formatDateValueForPdf } from "../../../components/functions/formatDate";

export interface PDFTableContainerProps {
    devisWithoutSinistre: boolean | undefined;
    numero_dossier: string | undefined;
    nom_charge_affaire: string | undefined;
    tel_charge_affaire: string | undefined;
    nom_expert: string | undefined;
    ref_sinistre: string | undefined;
    type_sinistre: string | undefined;
    nom_autre: string | undefined;
    nom_plateforme: string | undefined;
    nom_do: string | undefined;
    date_creation: Date | undefined;
    ref_devis: string | undefined;
    tiers: string | undefined;
    nom_client: string | undefined;
    rue_sinistre: string | undefined;
    adresse_client: string | undefined;
    ville_sinistre: string | undefined;
    ville_client: string | undefined;
    code_postal_client: string | undefined;
    cp_sinistre: string | undefined;
    compl_1_client: string | undefined;
    compl_2_client: string | undefined;
    loc_client: string | undefined;
    compl_1_sinistre: string | undefined;
    compl_2_sinistre: string | undefined;
    loc_sinistre: string | undefined;
}

export const PDFTableContainer = ({ numero_dossier, nom_charge_affaire, tel_charge_affaire, nom_expert, ref_sinistre, type_sinistre, nom_autre, nom_plateforme, nom_do, date_creation, ref_devis, tiers, nom_client, rue_sinistre, adresse_client, ville_sinistre, ville_client, code_postal_client, cp_sinistre, compl_1_client, compl_2_client, loc_client, compl_1_sinistre, compl_2_sinistre, loc_sinistre, devisWithoutSinistre }: PDFTableContainerProps) => {
    const styles = getPDFTableContainerStyles();

    const formattedDateResult = formatDateValueForPdf(date_creation);
    const formattedDate = formattedDateResult.formattedDate;
    const formattedDateValidite = formattedDateResult.formattedDateValidite;

    return (
        <View style={styles.tableContainer}>
            <View style={styles.tableInfos}>
                <View style={styles.styleSection}>
                    <Text style={styles.labelText}>Date :</Text>
                    <Text style={{ ...styles.labelText, fontWeight: "black" }}>{formattedDate}</Text>
                </View>
                <View style={styles.styleSection}>
                    <Text style={styles.labelText}>Validité :</Text>
                    <Text style={{ ...styles.labelText, fontWeight: "black" }}>{formattedDateValidite}</Text>
                </View>
                <View style={styles.styleSection}>
                    <Text style={styles.labelText}>Réf. :</Text>
                    <Text style={{ ...styles.labelText, fontWeight: "black" }}>{ref_devis}</Text>
                </View>
                <View style={styles.styleSection}>
                    <Text style={styles.labelText}>Affaire N°</Text>
                    <Text style={{ ...styles.labelText, fontWeight: "black" }}>{numero_dossier}</Text>
                </View>
                <View style={styles.styleSection}>
                    <Text style={styles.labelText}>Charg.affaire :</Text>
                    <Text>{nom_charge_affaire}</Text>
                </View>
                <View style={styles.styleSection}>
                    <Text style={styles.labelText}>Tél. :</Text>
                    <Text style={styles.labelText}>{tel_charge_affaire}</Text>
                </View>
                <View></View>
            </View>
            <View style={styles.tableInfos}>
                {!devisWithoutSinistre && 
                    <>
                        <View style={styles.styleSection}>
                            <Text style={styles.labelText}>Comp. assurance :</Text>
                            <Text style={styles.labelText}>{nom_do}</Text>
                        </View>
                        <View style={styles.styleSection}>
                            <Text style={styles.labelText}>Expert :</Text>
                            <Text style={styles.labelText}>{nom_expert}</Text>
                        </View>
                        <View style={styles.styleSection}>
                            <Text style={styles.labelText}>
                                Plateforme :
                            </Text>
                            <Text style={styles.labelText}>
                                {nom_plateforme}
                            </Text>
                        </View>
                        <View style={styles.styleSection}>
                            <Text style={styles.labelText}>Autre tiers :</Text>
                            <Text style={styles.labelText}>{nom_autre}</Text>
                        </View>
                    </>
                }
                <View style={styles.styleSection}>
                    {devisWithoutSinistre
                        ? <Text style={{ ...styles.labelText, fontWeight: "black" }}>Réf. dossier :</Text>
                        : <Text style={styles.labelText}>Réf. sinistre</Text>
                    }
                    <Text style={styles.labelText}>{ref_sinistre}</Text> {/*Attente réponse Jodie si ref.dossier = ref.sinistre*/}
                </View>
            </View>
            <View style={{ ...styles.tableInfos, borderRight: 'none' }}>
                {!devisWithoutSinistre && 
                    <View style={styles.styleSection}>
                        <Text style={styles.labelText}>Type de sinistre :</Text>
                        <Text style={styles.labelText}>{type_sinistre}</Text>
                    </View>
                }
                <View style={styles.styleSection}>
                    {devisWithoutSinistre
                        ? <Text style={{ ...styles.labelText, fontWeight: "black" }}>Client :</Text>
                        : <Text style={styles.labelText}>Assuré :</Text>
                    }
                    <View style={styles.styleSectionAdresse}>
                        <Text>{tiers}</Text>
                        <Text style={styles.labelText}>{nom_client}</Text>
                    </View>
                </View>
                <View style={{ gap: 15 }}>
                    <View >
                        {devisWithoutSinistre
                            ? <Text style={{ ...styles.labelText, fontWeight: "black", width: "60%" }}>Adresse d'intervention :</Text>
                            : <Text style={styles.labelText}>Adresse du sinistre :</Text>
                        }
                        <Text style={{ fontSize: 8 }}>{rue_sinistre || adresse_client}</Text>
                        <Text style={{ fontSize: 8 }}>{compl_1_sinistre || compl_1_client}</Text>
                        <Text style={{ fontSize: 8 }}>{compl_2_sinistre || compl_2_client}</Text>
                    </View>
                    <View style={styles.styleSectionAdresseDetails}>
                        <Text style={{ fontSize: 8 }}>{cp_sinistre || code_postal_client}</Text>
                        <Text style={{ fontSize: 8 }}>{ville_sinistre || ville_client}</Text>
                        <Text style={{ fontSize: 8 }}>
                            {loc_sinistre || loc_client 
                                ? 'Localisation : ' + loc_sinistre || loc_client
                                : ''
                            }
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    )
}

export const getPDFTableContainerStyles = () => {
    return StyleSheet.create({
        tableContainer: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            fontSize: 9,
            border: '1px solid #3a3a3a',
            borderRadius: 5,
        },
        tableInfos: {
            width: '33%',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            padding: 3,
            borderRight: '1px solid #3a3a3a',

        },
        styleSection: {
            width: '100%',
            display: "flex",
            flexDirection: 'row',
            gap: 1
        },
        labelText: {
            width: '50%',
        },
        styleSectionAdresse: {
            display: 'flex',
            flexDirection: 'column',
        },
        styleSectionAdresseDetails: {
            display: 'flex',
            flexDirection: 'row',
            gap: 15
        }
    })
}