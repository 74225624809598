import { Image, StyleSheet, Text, View } from "@react-pdf/renderer"
import { DevisInfos } from "../../../class/devis";

export interface PDFLogoProps {
    devisInfos: DevisInfos;
    pageNumber?: number;
}


export const PDFLogo = ({ devisInfos, pageNumber }: PDFLogoProps) => {
    const styles = getPdfLogoStyleLocal();
    const { affaire, num_piece } = devisInfos;
    return (
        <View style={styles.compagnyLogo} fixed>
            <Image
                src='/assets/img/client/logo-client.png' />
            {/* ⚠️ Conditionner l'affichage des deux textes, toujours affichés sauf sur la première page  */}
            {pageNumber && pageNumber > 1 && (
                <>
                    <Text style={styles.nom_agence}>{affaire?.nom_agence}</Text>
                    {/* ⚠️ Ajouter le champ `type devis` pour remplacer le `O` */}
                    <Text>N° devis : <span style={styles.num_devis}>{num_piece}DEV1600007150/ O</span></Text>
                </>
            )}


        </View>
    )
}

export const getPdfLogoStyleLocal = () => {
    return StyleSheet.create({
        compagnyLogo: {
            width: 200,
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 10
        },
        nom_agence: {
            color: '#a9a9a9',
            fontWeight: 900,
            fontSize: 12
        },
        num_devis: {
            fontWeight: 900,
            textTransform: 'uppercase'
        }
    })
}