import axios from "axios";
import { API } from "../Constantes";
import authHeader from "./AuthHeader";
import { encodeParams } from "../components/functions/requestTools";
import { TemplateMail } from "../class/templateMail";
import { PdfModel } from "../options/pdf-export/models/pdf-model-list";
import { pdf } from "@react-pdf/renderer";


class TemplateMailService {
    delTemplate(id: number) {
        return axios.post(API + "/mail/del/template",
            encodeParams({ id }), {
            headers: authHeader()
        });
    }

    async updateTemplate(template: TemplateMail) {
        const response = await axios.post(API + "/mail/update/template",
            encodeParams({ template: JSON.stringify(template) }), {
            headers: authHeader()
        });

        return response.data;
    }

    async addTemplate(template: TemplateMail) {
        const response = await axios
            .post(API + '/mail/create/template',
                encodeParams({ template: JSON.stringify(template) }), {
                headers: authHeader()
            });

        return response.data;
    }

    async addMailPdf(pdfModel: PdfModel) {
        try {
            const pdfBlob = await pdf(pdfModel?.rendu).toBlob();
            const formData = new FormData();
            formData.append('pdfFile', pdfBlob, pdfModel?.name);

            await axios.post(`${API}mail/store/pdf`, formData, {
                headers: {
                    ...authHeader(),
                    'Content-Type': 'multipart/form-data',
                },
            });

        } catch (error) {
            console.error("Erreur lors de la génération et de l'envoi du PDF :", error);
        }
    }

    async sendEmailRequest(email: string, setLoading: (loading: boolean) => void, fileName: string, contentTemplate: string) {
        setLoading(true);

        const response = await axios
            .post(API + "email/template/send", {
                email: email,
                contentTemplate: contentTemplate,
                fileName: fileName,
            }, {
                headers: authHeader(),
            })
            .then(function (response: any) {
                return response.data;
            })
            .catch(function (error: any) {
                return { error: error.message };
            })
            .finally(() => {
                setLoading(false);
            });

        return response;
    }
}
const templateMailService = new TemplateMailService();
export default templateMailService;