import { unstable_useBlocker as useBlocker, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { DevisInfos, DevisValeur, ListeDeroulanteDevis } from "../../../class/devis";
import Header from "../../header/header";
import { Icon } from "../../tools/icon";
import DevisBody from "./devis-body";
import DevisFooter from "./devis-footer";
import DevisHeader from "./devis-header";
import { ActionDevis } from "./devis-infos-reducer";
import { ArticleFamilles, ArticleOrLot, Nota } from "../../../class/articles";
import { ChangeEvent, Dispatch, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import DevisService from "../../../services/DevisService";
import { formatUrl } from "../../functions/formatUrl";
import { devisExitReducer } from "./devis-exit-reducer";
import ConfirmModal from "../../tools/confirm-modal";
import { useFeature } from "flagged";
import { PdfExportButton } from "../../../options/pdf-export/pdf-export-button";
import { DevisTypeCode, corresp_provenance_accord_devis, corresp_statuts_devis } from "../../../class/document";
import formatDate, { formatDateTime } from "../../functions/formatDate";
import { formatToValidNumber } from "../calculatrice/calculatrice-reducer";
import { CalculatriceModal } from "./modal/calculatrice-modal";
import DevisSelect from "./devis-select";
import DatePicker from 'react-datepicker'
import { devisPrefix } from "../../../formatters/devisFormatter";
import { SendMailModal } from "../mails/send-mail-modal";
import { customerOptions } from "../../../Options";



interface DevisInfosWrapperProps {
    isNewDevis?: boolean;
    inDivalto?: boolean;
    articles?: ArticleOrLot[];
    notaList?: Nota[];
    devisInfos: DevisInfos | undefined;
    devisInfosDispatch: Dispatch<ActionDevis>;
    ref?: any;
}

const DevisInfosWrapper = ({ isNewDevis = false, inDivalto, articles = [], notaList = [], devisInfos, devisInfosDispatch, ref }: DevisInfosWrapperProps) => {

    const isPDFExport = useFeature('PDFExport');
    const isDocumentListeOPT = useFeature('DocumentListe');
    const [showTitreError, setShowTitreError] = useState(false);
    const [showSousMetierError, setShowSousMetierError] = useState(false);
    const [showTypeDevisError, setShowTypeDevisError] = useState(false);
    // const [showAdresseError, setShowAdresseError] = useState(false);
    const [showSaveModal, SetshowSaveModal] = useState({ isOpen: false, type: '' });
    const saveModalType = showSaveModal.type;
    const saveQuitModal = 'save-quit', quitModal = 'quit', redirectModal = 'redirect', saveModal = 'save';

    const navigate = useNavigate();
    const { state } = useLocation();
    const isFromAffaire = state?.from === 'affaire';
    const devisName = devisInfos?.num_piece ? (devisInfos.is_devis ? devisPrefix + devisInfos?.num_piece : devisInfos?.num_piece) : devisInfos?.ref || '';
    const editable = isNewDevis || !inDivalto;
    //const editable = true;
    const [afficheSave, setAfficheSave] = useState(false);
    const pageExitCheckerStart = { bilan: true, bilanRows: true, notOneRow: true };
    const [pageExitChecker, exitDispach] = useReducer(devisExitReducer, pageExitCheckerStart);

    const shouldBlock = !pageExitChecker.bilanRows && (!showSaveModal.isOpen || saveModalType === redirectModal)
    const navBlocker = useBlocker(
        useCallback(() => (
            shouldBlock
        ), [shouldBlock])
    );

    const updateAfficheSave = (value: boolean) => setAfficheSave(value);
    const manageSafeExit = (key: string, isSafe: boolean) => exitDispach({ key: key, value: isSafe });
    const nbLignes = devisInfos?.lignes.length;
    const listesArticles = useMemo(() => getListeArticle(articles), [articles]);
    const listesDeroulantesArticles = useMemo(() => formatListesDeroulantesArticle(listesArticles, devisInfos), [devisInfos?.metier, !devisInfos])

    useEffect(() => {
        navBlocker.state === 'blocked'
            && !showSaveModal.isOpen
            && SetshowSaveModal({ isOpen: true, type: redirectModal });
    }, [navBlocker.state])

    useEffect(() => {
        const newNotOneRow = !!nbLignes;
        (newNotOneRow !== pageExitChecker.notOneRow) && manageSafeExit('notOneRow', newNotOneRow);
    }, [nbLignes])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        showTitreError && setShowTitreError(!devisInfos?.ref);
        showSousMetierError && setShowSousMetierError(!devisInfos?.sous_metier.selection);
        showTypeDevisError && setShowTypeDevisError(!devisInfos?.devis_type.selection?.code);
    }, [(showTitreError && devisInfos?.ref), 
        (showSousMetierError && devisInfos?.sous_metier.selection), 
        (showTypeDevisError && devisInfos?.devis_type.selection?.code)]);  // eslint-disable-line react-hooks/exhaustive-deps
    /*useEffect(() => {
        showAdresseError && setShowAdresseError(!isAdresseSelected);
    }, [(showAdresseError && isAdresseSelected)]);*/  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const valoriseEmptyKey = (key: keyof listeArticle) =>
            devisInfos && !devisInfos?.[key] && !inDivalto && devisInfosDispatch({ key: key, value: listesDeroulantesArticles?.[key]?.possible[0]?.code });
        valoriseEmptyKey("metier");
        valoriseEmptyKey("sous_metier");
    }, [!devisInfos]);  // eslint-disable-line react-hooks/exhaustive-deps

    const [showCalculatrice, setShowCalculatrice] = useState(false);
    const handleCloseCalculatrice = () => setShowCalculatrice(false);
    const handleShowCalculatrice = () => setShowCalculatrice(true);

    //const devisHeaderRef = useRef<HTMLDivElement | null>(null);
    const [showInfos, setShowInfos] = useState(true);
    const scrollbarElement = document.querySelector('.scrollbar');

    const [showSendMailModal, setShowSendMailModal] = useState(false);

    const toggleInfos = () => {
        setShowInfos(!showInfos);
        if (!showInfos) {
            scrollbarElement?.scrollTo(0, 0);
        }
    };
    //date={formatDate(devisInfos.createDate, true, false, true)}
    const getHoursDevis = (hourlyRate: number, budgetPerHour: number) => {
        const calculatriceHoursReturn: { [key in keyof Partial<DevisInfos>]: number } = {
            budget_heure: budgetPerHour,
            taux_horaire: hourlyRate
        }
        devisInfosDispatch({ key: 'updateCalculatriceValues', value: calculatriceHoursReturn });
    }


    const buildOnChange = (champ: string, isFamille?: boolean) =>
        (e: DevisValeur) =>
            devisInfosDispatch({ key: champ, value: isFamille ? e.label : e });


    const [acceptedDevis, setAcceptedDevis] = useState(false);
    const [agreedDate, setAgreedDate] = useState(devisInfos?.date_accord ? devisInfos.date_accord : new Date());
    const [agreedStatut, setAgreedStatut] = useState(devisInfos?.provenance_accord ? devisInfos.provenance_accord : undefined);
    const [headerAcceptedStatut, setHeaderAcceptedStatut] = useState<{ [key in keyof Partial<DevisInfos>]: (Date | string | null) }>({ date_accord: null, provenance_accord: undefined });


    useEffect(() => {
        devisInfos?.date_accord && setAgreedDate(devisInfos.date_accord);
        devisInfos?.provenance_accord && setAgreedStatut(devisInfos.provenance_accord);
    }, [devisInfos?.date_accord, devisInfos?.provenance_accord])


    const addAgreedDate = (date: Date | null) => {
        date && setAgreedDate(date);
        if (date) {
            setHeaderAcceptedStatut(prevState => ({
                ...prevState,
                date_accord: new Date(date.setHours(0o6))
            }));
        }
    }

    const addSource = (e: string) => {
        e && setAgreedStatut(e as keyof typeof corresp_provenance_accord_devis);
        if (e in corresp_provenance_accord_devis) {
            setHeaderAcceptedStatut(prevState => ({
                ...prevState,
                provenance_accord: e as keyof typeof corresp_provenance_accord_devis
            }));
        }
    }

    useEffect(() => {
        if (devisInfos?.statut.selection?.code === '2') {
            setAcceptedDevis(true);
        } else {
            setAcceptedDevis(false);
            setAgreedStatut(undefined);
            setAgreedDate(new Date());
            setHeaderAcceptedStatut(prevState => ({
                ...prevState,
                date_accord: null,
                provenance_accord: undefined,
            }));
        }
    }, [devisInfos])

    useEffect(() => {
        !acceptedDevis && devisInfosDispatch({ key: 'updateHeaderAcceptedStatut', value: headerAcceptedStatut });
    }, [acceptedDevis])

    return (
        <div className="content-wrapper w-100 h-100 bg-bleu-clair2">
            <Header titre={`${getDocInfos('type')} | ${isNewDevis ? 'Nouveau Devis' : devisName}`}
                devisType={devisInfos?.devis_type?.selection?.code as DevisTypeCode}
                icone={'file-lines'}
                infos={false}
                recherche={false}
            />
            <main className="editdevis-wrapper">
                <div className="px-2 px-xl-4 entete bg-bleu-clair1">
                    <div className="d-md-flex align-items-center justify-content-between height-search">

                        <button onClick={safeExit} className={`nobt my-2 m-md-0 retour ${(isFromAffaire && !devisInfos) ? 'opacity-50' : ''}`}>
                            <span className="bg-bleu-actif btn-rounded me-2 blanc px-2 border-0 d-inline-block">
                                <span className="fa fa-chevron-left"></span>
                            </span>
                            {isFromAffaire
                                ? <div className="bleu-actif font-bold text-uppercase display-19">Retour à l'affaire</div>
                                : <div className="bleu-actif font-bold text-uppercase display-19">Retour liste des {isDocumentListeOPT ? getDocInfos('liste') : 'devis'}</div>
                            }
                        </button>

                        <div className="d-flex align-items-center mb-1 mb-md-0">

                            <div className="mx-2">
                                {editable && !isNewDevis &&
                                    <>
                                        <button className="btn btn-primary mx-1" onClick={handleShowCalculatrice}>
                                            <span className="hidemobile">Calculatrice</span> <span className="fa fa-calculator ms-1"></span>
                                        </button>
                                        <button className="hideme btn btn-orange me-0 me-md-2" >
                                            Transférer<span className="fa fa-check ms-1"></span>
                                        </button>
                                    </>
                                }
                                <button className="btn btn-primary mx-1" >
                                    <span className="hidemobile">Générer BDC SST</span> <span className="fa fa-file-alt ms-1"></span>
                                </button>
                            </div>

                            <div className="d-flex align-items-center mb-1 mb-md-0 mx-2">
                                {isPDFExport && <PdfExportButton devisInfos={devisInfos} />}
                                {editable && !isNewDevis &&
                                    <button className="btn btn-primary mx-1" onClick={() => { customerOptions.sendDevis && setShowSendMailModal(true) }}>
                                        <span className="hidemobile">Envoyer</span>
                                        <span className="fa fa-share ms-1"></span>
                                    </button>
                                }
                            </div>
                        </div>

                        {!isNewDevis && editable &&
                            <div className="d-lg-flex align-items-center justify-content-center">
                                <button onClick={safeExit} className="btn btn-secondary mb-2 mb-md-0 me-2">
                                    Annuler
                                </button>
                                <button className="btn btn-vert mb-2 mb-md-0" onClick={() => saveQuitDevisHeader(true)}>
                                    Sauvegarder et quitter
                                    <Icon icon='floppy-disk ms-2 blanc' />
                                </button>
                            </div>
                        }

                    </div>
                </div>

                <div className="px-2 px-xxl-4">
                    <div className="bg-blanc rounded-0 w-100">
                        <div className={`height-search border-bottom `}>

                            <div className="py-2 py-md-0 px-3 d-md-flex justify-content-between align-items-center h-100">
                                <div className="d-md-flex mb-1 mb-xl-0">

                                    <DevisSelect
                                        listeDeroulante={devisInfos?.statut}
                                        titre='Statut'
                                        editable={editable}
                                        isLoading={!devisInfos}
                                        onChange={buildOnChange('statut')}
                                    />
                                    {acceptedDevis &&
                                        <div className="d-md-flex align-items-center justify-content-start">
                                            <div className="d-flex align-items-center justify-content-start">
                                                <span className="nowrap me-1 ms-0 ms-md-2">Date d'accord : </span>
                                                <label>
                                                    <DatePicker
                                                        onChange={(date) => addAgreedDate(date)}
                                                        selected={agreedDate}
                                                        dateFormat="dd/MM/yyyy"
                                                        icon="fa fa-calendar"
                                                        showIcon
                                                        className="form-control mx-width-3"
                                                        closeOnScroll={true}
                                                        maxDate={new Date()}
                                                        minDate={devisInfos?.creation_date}
                                                    />
                                                </label>
                                            </div>
                                            {
                                                /*
                                                                                            <DevisSelect
                                                                                                listeDeroulante={{
                                                                                                    selection: {
                                                                                                        label: corresp_provenance_accord_devis[agreedStatut] || '', 
                                                                                                    },
                                                                                                    possible: Object.entries(corresp_provenance_accord_devis).map(([key, label]) => ({ code: key, label })),
                                                                                                }}
                                                                                                titre="Provenance"  // Set your desired title
                                                                                                editable={true}
                                                                                                onChange={(selectedOption) => addSource(selectedOption.code)}  
                                                                                                isError={false}  // Set your error condition
                                                                                                isLoading={false}  // Set your loading condition
                                                                                            />
                                                */

                                            }

                                            <div className="d-flex align-items-center justify-content-start">
                                                <span className="nowrap me-1 ms-0 ms-md-2">Provenance : </span>
                                                <select
                                                    className="form-select form-control"
                                                    id="pourcent2"
                                                    onChange={(e) => addSource(e.target.value)}
                                                    value={agreedStatut}
                                                >
                                                    {Object.entries(corresp_provenance_accord_devis)?.map(([key, source]) =>
                                                        <option key={key} value={key}>{source}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    }



                                    {
                                    /*
                                    <label className="mb-2 nowrap">
                                        <span>Statut :</span>
                                    </label>
                                    <select
                                        className="form-select"
                                        disabled={!editable}
                                        value={devisInfos?.statut}
                                        onChange={(e) => changeStatut(e)}>
                                        {Object.entries(corresp_statuts_devis)?.map(([key, statut]) =>
                                            <option key={key} value={key}>{statut}</option>
                                        )}
                                    </select>
                                        */}





                                </div>
                                <div className="small px-2 hideme">
                                    {
                                        //<span className="font-bold vert text-transform">{corresp_statuts_devis[devisInfos?.statut || "1"]}</span><br />
                                    }
                                    <span>Date d'envoi :   {formatDate(devisInfos?.date_envoi)}</span>, <span>Date d'accord :   {formatDate(devisInfos?.date_accord, true)}</span>, <span>Provenance accord :   {corresp_provenance_accord_devis[devisInfos?.provenance_accord || "0"]}</span><br />
                                    (Créé par : <span className="font-bold">{devisInfos?.user_name_creation}, le {formatDateTime(devisInfos?.creation_date || new Date(), true)}</span> - Modifié par : <span className="font-bold">{devisInfos?.user_name_modification || 'non renseigné'}, le {formatDateTime(devisInfos?.modification_date || new Date(), true) || 'non renseigné'}</span>)
                                </div>

                                <div><button className="btn btn-infos bg-bleu-clair3 text-uppercase d-flex align-items-center" onClick={toggleInfos}><span className="nowrap">{showInfos ? 'Masquer les infos' : 'Afficher les infos'}</span> <span className={`fa fa-chevron-${showInfos ? 'up' : 'down'} ms-2`}></span></button></div>


                            </div>

                        </div>

                        <div className={`scrollbar container-fluid noir w-100 height-6`}>
                            {afficheSave &&
                                <div className={`preloadSave display-15 d-flex h-100 w-100 position-absolute d-flex align-items-center justify-content-center z-100 top-0 start-0 bg-clair ${afficheSave ? "show" : ""}`}>
                                    <Icon icon='check vert me-2' />
                                    <span className="font-bold display-17">
                                        Devis enregistré
                                    </span>
                                </div>}


                            {showInfos &&
                                <DevisHeader
                                    devisInfos={devisInfos}
                                    devisInfosDispatch={devisInfosDispatch}
                                    editable={editable}
                                    showTitreError={showTitreError}
                                    showSousMetierError={showSousMetierError}
                                    showTypeDevisError={showTypeDevisError}
                                    showAdresseError={false}
                                    listesDeroulantesArticles={listesDeroulantesArticles}
                                    saveDevisHeader={saveQuitDevisHeader}
                                    openCalculatrice={handleShowCalculatrice} />
                            }
                            {!isNewDevis &&
                                <DevisBody devisInfos={devisInfos}
                                    articles={articles}
                                    notaList={notaList}
                                    devisInfosDispatch={devisInfosDispatch}
                                    manageSafeExit={manageSafeExit}
                                    editable={editable}
                                    openCalculatrice={handleShowCalculatrice}
                                />}
                            {editable && !isNewDevis &&
                                <DevisFooter devisId={devisInfos?.id ?? -1}
                                    devisName={devisName}
                                    safeTransfer={safeTransfer} />}

                        </div>
                    </div>
                </div>
            </main>
            <ConfirmModal show={showSaveModal.isOpen}
                title={'Attention'}
                message={getModalMessage()}
                onConfirm={handleModalConfirm}
                onCancel={handleModalCancel}
                cantCancel={saveModalType === saveModal}
                isRed />

            <CalculatriceModal
                showCalculatrice={showCalculatrice}
                handleCloseCalculatrice={handleCloseCalculatrice}
                devisInfos={devisInfos}
                getHoursDevis={getHoursDevis} />

            <SendMailModal show={showSendMailModal} onHide={() => setShowSendMailModal(false)} fonctionnality="" devisInfos={devisInfos} />
        </div>
    )


    function getDocInfos(type: 'type' | 'liste') {
        const infos = [
            ['Devis', 'Bon de commande', 'Bon de livraison', 'Facture', 'Devis'], // index 4 = default value
            ['devis', 'documents', 'documents', 'documents', 'devis'],
            // ['devis', 'bons de commande', 'bons de livraison', 'factures', 'documents'],
        ]
        const index = type === 'type' ? 0 : 1;
        return devisInfos?.is_devis ? infos[index][0]
            /*: devisInfos?.is_bon_commande ? infos[index][1]
                : devisInfos?.is_bon_commande ? infos[index][2]
                    : devisInfos?.is_facture ? infos[index][3]*/
            : infos[index][4] // Pour les types inconnus
    }

    function safeExit() {
        checkSafeExit(false, true) && leavePage();
    }
    function safeTransfer() {
        return checkSafeExit(true);
    }

    function saveQuitDevisHeader(leave: boolean, forceLeave?: boolean) {
        if (!devisInfos) return;
        if (!devisInfos.ref || !devisInfos.sous_metier.selection || !devisInfos.devis_type.selection?.code) {
            setShowTitreError(!devisInfos.ref);
            setShowSousMetierError(!devisInfos.sous_metier.selection);
            setShowTypeDevisError(!devisInfos.devis_type.selection?.code);
            return;
        }
        if (headerAcceptedStatut.provenance_accord && headerAcceptedStatut.date_accord) {
            devisInfosDispatch({ key: 'updateHeaderAcceptedStatut', value: headerAcceptedStatut });
        }
        // if (!devisInfos.ref) { //|| !isAdresseSelected
        //     setShowTitreError(!devisInfos.ref);
        //     // setShowAdresseError(!isAdresseSelected);
        //     return;
        // };
        if (leave && !forceLeave && !checkSafeExit(true, true)) return;

        showSaveAffichage();

        DevisService.setDevisHeader(devisInfos).then(
            id => leave ? leavePage()
                : isNewDevis && id && navigate(formatUrl('devis/local', id), { state: { from: state?.from } })
        );
    }

    function checkSafeExit(save?: boolean, quit?: boolean) {
        const modalName = save ? quit ? saveQuitModal : saveModal : quitModal;
        const isNotSafe = quit ? (!pageExitChecker.bilanRows) : (!pageExitChecker.bilan);
        isNotSafe && SetshowSaveModal({ isOpen: true, type: modalName });
        return !isNotSafe;
    }

    function handleModalConfirm() {
        saveModalType === quitModal || saveModalType === redirectModal
            ? leavePage()
            : saveModalType === saveQuitModal
            && saveQuitDevisHeader(true, true);
        closeModal();
    }
    function handleModalCancel() {
        navBlocker.state === 'blocked' && navBlocker.reset();
        closeModal();
    }

    function closeModal() {
        SetshowSaveModal(prev => ({ ...prev, isOpen: false }));
    }

    function leavePage() {
        // Risque de casse si nouvel accès a la creation d'un devis (17/01/2024)
        // Potentielles solutions : rtansmetter un state au devis / Utiliser le replace du navigate après la création du devis
        const destination = isFromAffaire ? 'affaire/' + devisInfos?.affaire?.id
            : isDocumentListeOPT ? (devisInfos?.is_devis ? 'devis' : 'documents') : 'devis';
        navBlocker.state === 'blocked'
            ? navBlocker.proceed()
            : isFromAffaire
                ? devisInfos && navigate(formatUrl(destination))
                : navigate(-1);
    };

    function showSaveAffichage() {
        updateAfficheSave(true);
        setTimeout(() => updateAfficheSave(false), 1500);
    };

    function getModalMessage() {
        const rowPb = "Le devis ne contient aucune ligne.";
        const leaveMessage = "Le devis contient des modifications qui n'ont pas été enregistrées. Êtes-vous sûr de vouloir quitter cette page ?";
        return saveModalType === saveModal && pageExitChecker.bilanRows
            ? rowPb
            : leaveMessage;
    }

    function getListeArticle(articles: ArticleOrLot[]): listeArticle {
        const keys: (keyof ArticleFamilles)[] = ['metier', 'sous_metier'];
        return keys.reduce((acc, curr) => {
            acc[curr] = formatListeArticle(articles, curr);
            return acc;
        }, {} as listeArticle)
    }

    function formatListeArticle(articles: ArticleOrLot[], key: keyof listeArticle): DevisValeur[] {
        const liste = [...new Set(articles.map(article => article[key]))] as string[];
        return liste.map(prop => ({ code: prop, label: prop })).sort();
    }

    function formatListesDeroulantesArticle(listes: listeArticle, devisInfos: DevisInfos | undefined): { [key in keyof listeArticle]: ListeDeroulanteDevis } {
        return Object.keys(listes).map(k => {
            const key = k as keyof listeArticle;
            const selectValue = devisInfos?.[key]?.selection?.code;
            return ({
                [key]: {
                    possible: listes[key],
                    selection: {
                        code: selectValue,
                        label: selectValue,
                    },
                } as ListeDeroulanteDevis
            })
        }
        ).reduce((a, b) => ({ ...a, ...b }), {}) as { [key in keyof listeArticle]: ListeDeroulanteDevis };
    }
}

type listeArticle = {
    [key in keyof ArticleFamilles]: DevisValeur[];
};


export default DevisInfosWrapper;