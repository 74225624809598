

import { Text, StyleSheet, View } from '@react-pdf/renderer';
import formatMontant from '../../../components/functions/formatMontant';

export interface PDFLigneTitreProps {
    text: string;
    montant_total?: number;
    hidePrice?: boolean;
    typeTitre: number
}

export const PDFLigneTitre = ({ text, montant_total, hidePrice, typeTitre }: PDFLigneTitreProps) => {
    const styles = pdfLigneCommentaireStylesLocal;

    const titreStyle = typeTitre === 1
        ? styles.titreType1
        : typeTitre === 2
            ? styles.titreType2
            : typeTitre === 3
                ? styles.titreType3
                : {};

    const montantStyle = typeTitre === 1
        ? styles.montantStyle1
        : typeTitre === 2
            ? styles.montantStyle2
            : typeTitre === 3
                ? styles.montantStyle3
                : {};

    return (
        <View style={styles.ligne_titre}>
            {/* ⚠️ Mettre le sous-titre de type 3 en italique */}
            <Text style={[styles.commentaire, titreStyle]}>{text || ' '}</Text>
            {/* ⚠️ Supprimer le symbole € */}
            {!hidePrice && <Text style={[styles.montant_total, montantStyle]}>{formatMontant(montant_total, false)}</Text>}
        </View>
    )
};

export const pdfLigneCommentaireStylesLocal = StyleSheet.create({

    ligne_titre: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 10,
        textTransform: 'uppercase'
    },
    commentaire: {
        fontWeight: 'bold',
        paddingVertical: 4,
    },
    montant_total: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        fontWeight: "black",
        paddingRight: 40
    },
    titreType1: {
        fontSize: 17,
        fontWeight: 900,
        textDecoration: 'underline',
    },
    titreType2: {
        fontSize: 14,
        color: '#2b0fba',
        fontWeight: 900,
        paddingLeft: 5,
    },
    titreType3: {
        fontSize: 10,
        paddingLeft: 12,
    },
    montantStyle1: {
        fontSize: 17,
        fontWeight: 900,
        textDecoration: 'underline',
    },
    montantStyle2: {
        fontSize: 14,
        color: '#2b0fba',
        fontWeight: 900,
    },
    montantStyle3: {
        fontSize: 10,
    }
});

