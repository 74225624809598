import { StyleSheet, Text, View } from "@react-pdf/renderer"

export interface PDFBodyHeaderProps {
    hidePrice?: boolean | undefined;
}

export const PDFBodyHeader = ({ hidePrice }: PDFBodyHeaderProps) => {
    const styles = getPdfBodyHeaderStyleLocalNew({ hidePrice });

    return (
        <View style={styles.bodyHeaderContainer} fixed>
            <Text style={styles.designationStyle}>Désignation</Text>
            <View style={styles.subHeader}>
                <Text style={styles.subHeaderText}>Quantité</Text>
                <Text style={styles.subHeaderText}>U.V</Text>
                {!hidePrice && (
                    <>
                        <Text style={styles.subHeaderText}>Prix unitaire</Text>
                        <Text style={styles.subHeaderText}>Rem</Text>
                        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'right' }}>
                            <Text>Montant</Text>
                            <Text>Remisé</Text>
                        </View>
                        <Text style={{ fontSize: 8, fontWeight: 'light', textAlign: 'right' }}> OP</Text>
                    </>
                )}
            </View>
        </View>
    )
}

export const getPdfBodyHeaderStyleLocalNew = ({ hidePrice }: PDFBodyHeaderProps) => {
    return StyleSheet.create({
        bodyHeaderContainer: {
            marginTop: 4,
            paddingLeft: 10,
            paddingRight: 15,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: "#e3e3e3",
            width: '100%',
        },
        designationStyle: {
            width: hidePrice ? '90%' : '70%',
        },

        subHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: hidePrice ? 'flex-end' : 'center',
            gap: hidePrice ? '5' : '8',
            width: hidePrice ? '15% ' : '40%',
        },

        subHeaderText: {
            textAlign: 'right',
            flex: hidePrice ? 1 : 'auto',

        }

    })
}