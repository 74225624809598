import { Routes, Route, Navigate, Outlet } from 'react-router';
import AuthProvider from './provider/AuthProvider';
import ProtectedRoute from './routes/ProtectedRoute';
import Connexion from './webpages/connexion';
import Home from './webpages/home';
import DevisListe from './webpages/devis-list';
import Error from './webpages/error';
import Sidebar from './components/sidebar/sidebar';
import NouveauDevis from './webpages/nouveau-devis';
import Utilisateurs from './webpages/utilisateurs';
import EditDevis from './webpages/edit-devis';
import LectureDevis from './webpages/lecture-devis';
import './App.css';
import RoleSwitcher from './routes/RoleSwitcher';
import { FlagsProvider } from 'flagged';
import { customerOptions } from './Options';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import DocumentListe from './webpages/document-liste';
import Calculatrice from './webpages/calculatrice';
import Affaires from './webpages/affaires';
import Qualite from './webpages/qualite';
import Reporting from './webpages/reporting';
import AffaireDashboard from './webpages/affaire-dashboard';
import Mails from './webpages/mails';
import EditMails from './webpages/edit-mails';
import BPUs from './webpages/bpus';
import Ouvrages from './webpages/ouvrages';
import Notas from './webpages/notas';
import BDC from './webpages/bdc';

//import Connexion from './webpages/connexion';

function App() {
	const {
		DocumentListe: isDocumentListeEnabled,
		DocumentDetails: isDocumentDetailsEnabled
	} = customerOptions;

	return (
		<FlagsProvider features={customerOptions}>
			<AuthProvider>
				<RouterProvider router={createBrowserRouter([
					{
						index: true,
						path: "/connexion",
						element: <Connexion />
					},
					{
						path: "/*",
						element: (<ProtectedRoute>
							<Sidebar />
							<Outlet />
						</ProtectedRoute>),
						children: [
							{
								path: "*",
								element:
									<RoleSwitcher UserRoutes={
										<Routes>
											<Route path="/" element={<Navigate to='/tableau-de-bord' />} />
											<Route path="/calculatrice" element={<Calculatrice />} />
											<Route path="*" element={<Error errorType={''} />} />
											<Route path="devis" element={<DevisListe />} />
											{isDocumentListeEnabled && <Route path="documents" element={<DocumentListe />} />}
											{isDocumentDetailsEnabled && <Route path="document/gescom/:devisId" element={<LectureDevis />} />}
											<Route path="devis/local/:devisId" element={<EditDevis />} />
											<Route path="devis/divalto/:devisId" element={<LectureDevis />} />
											<Route path="devis/nouveau/:affaireId" element={<NouveauDevis />} />
											<Route path="tableau-de-bord" element={<Home />} />
											<Route path="affaire/:affaireId" element={<AffaireDashboard />} />
											<Route path="affaires" element={<Affaires />} />
											<Route path="reporting" element={<Reporting />} />
											<Route path="reporting/:reportId" element={<Reporting />} />
											<Route path="qualite" element={<Qualite />} />
											<Route path="bdc" element={<BDC />} />
										</Routes>
									} AdminRoutes={
										<Routes>
											<Route path="*" element={<Navigate to="/mails" />} />
											<Route path="mails" element={<Mails />} />
											<Route path="bpus" element={<BPUs />} />
											<Route path="ouvrages" element={<Ouvrages />} />
											<Route path="notas" element={<Notas />} />
											<Route path="utilisateurs" element={<Utilisateurs />} />
											<Route path="create-mail-template" element={<EditMails />} />
											<Route path="mails/:templateId" element={<EditMails />} />
										</Routes>
									} />
							},
						],
					}
				])} />
			</AuthProvider>
		</FlagsProvider>
	);
}
export default App;

//<Route path="/aboutus" element={<Aboutus />} />


// Old routes : 
/*<Route>
	<Route path="/*" element={
		<ProtectedRoute>
			<Sidebar />
			<Outlet />
			<Routes />
		</ProtectedRoute>
	}>
		<Route path="*" element={
			<RoleSwitcher UserRoutes={
				<Routes>
					<Route path="/" element={<Navigate to='/tableau-de-bord' />} />
					<Route path="*" element={<Error errorType={''} />} />
					<Route path="devis" element={<DevisListe />} />
					<Route path="devis/local/:devisId" element={<EditDevis />} />
					<Route path="devis/gescom/:devisId" element={<LectureDevis />} />
					<Route path="devis/nouveau/:clientId" element={<NouveauDevis />} />
					<Route path="tableau-de-bord" element={<Home />} />
					<Route path="clients" element={<Clients />} />
					<Route path="client/:clientId" element={<ClientDashboard />} />
					<Route path="articles" element={<Articles />} />
				</Routes>
			} AdminRoutes={
				<Routes>
					<Route path="*" element={<Navigate to="/utilisateurs" />} />
					<Route path="utilisateurs" element={<Utilisateurs />} />
				</Routes>
			} />
		} />
	</Route>
	<Route index path="/connexion" element={<Connexion />} />
	<Route path="*" element={<Error errorType={''} />} />
</Route>)*/
