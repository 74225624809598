import { Dispatch, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { LigneDevis } from "./devis-ligne/devis-ligne";
import { DevisInfos, DevisLigneArticleBase, DevisLigneBaseTypes, DevisLigneEdit, createDevisLigne } from "../../../class/devis";
import { ActionDevis } from "./devis-infos-reducer";
import { ArticleOrLot, ArticleSimple, BibliothequeMetier, LotArticles, Nota } from "../../../class/articles";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { useStrictDroppable } from "./useStrictDroppable";
import ConfirmModal from "../../tools/confirm-modal";
import SelectLotContentModal from "./devis-ligne/select-lot-content-modal";
import { PhotoModal } from "../articles/photo-modal";
import { photoModalArticleProps } from "../articles/articleswrapper";
import DevisBodyTotaux from "./devis-body/devis-body-totaux";
import { DevisBodyHeader } from "./devis-body-header";
import { SelectionManager, devisSelectReducer, initDevisSelectReducer } from "./devis-selection-reducer";
import DevisService from "../../../services/DevisService";
import WrapperBpus, { formattedLabelOfDevis } from "./bibliotheque-bpu/wrapper-bpus";
import { createMap } from "../../functions/formatObject";
import { useTVAListe } from "../../../hooks/useArticles";
import formatMontant from "../../functions/formatMontant";



interface DevisBodyProps {
    devisInfos: DevisInfos | undefined;
    articles: ArticleOrLot[];
    notaList: Nota[];
    devisInfosDispatch: Dispatch<ActionDevis>;
    manageSafeExit: (key: string, isSafe: boolean) => void;
    editable: boolean;
    openCalculatrice: any,
}


const DevisBody = ({ editable, devisInfos, articles, notaList, devisInfosDispatch, manageSafeExit, openCalculatrice }: DevisBodyProps) => {

    const selectionMultipleDel = 'multiple';
    const lignesIds = devisInfos?.lignes.map(l => l.id);
    const TVAListe = useTVAListe() || [];
    const [showDelLigneModal, setShowDelLigneModal] = useState(0 as number | string);
    const devisSelection = useReducer(devisSelectReducer, initDevisSelectReducer(lignesIds));
    const selectionManager: SelectionManager = { ...devisSelection[0], dispatch: devisSelection[1] };
    const selectedLignes = selectionManager.selectedLignes;
    const selectLotContentModalStart = { isOpen: false, lot: undefined as undefined | LotArticles, saveEditLot: (_: LotArticles) => { } };
    const [photoModalArticle, setPhotoModalArticle] = useState<photoModalArticleProps>({ show: false, photo: '' });
    const selectArticlePhoto = (show: boolean, p: string) => setPhotoModalArticle({ show: show, photo: p });
    const [showSelectLotContentModal, setShowSelectLotContentModal] = useState(selectLotContentModalStart);
    const openSelectLotContentModal = (lot: LotArticles, saveEditLot: (selectedArticle: LotArticles) => void) =>
        setShowSelectLotContentModal({ isOpen: true, lot, saveEditLot });
    const closeSelectLotContentModal = () => setShowSelectLotContentModal(prev => ({ ...prev, isOpen: false }));
    const [enabledDroppable] = useStrictDroppable(false);

    const maxOrdre = devisInfos ? (devisInfos?.lignes.length + 1) * 1000 : -1;
    const emptyDevisLigne = useMemo(() => createDevisLigne(maxOrdre, 0), [maxOrdre]);

    const filtredArticles = useMemo(() => (filterArticles(articles, devisInfos) || []), [articles, devisInfos?.code_bpu, devisInfos?.metier.selection?.label]);
    const [filtredLots, filtredArticlesSimple] = useMemo(() => splitArticleAndLot(filtredArticles), [filtredArticles.length]);
    //const remiseClient = isRemiseClientInArticle ? devisInfos?.client.remise : 0;
    //const emptyDevisLigne = useMemo(() => createDevisLigne(maxOrdre, remiseClient), [maxOrdre]);

    const openDelLigneModal = (id: number) => setShowDelLigneModal(id);
    const handleModalCancel = () => setShowDelLigneModal(0);
    const handleModalConfirm = () => {
        const ligneId = showDelLigneModal;
        const isMultiple = ligneId === selectionMultipleDel;
        changeSelectedLigneAfterDelete(isMultiple ? selectedLignes : [(ligneId as number)]);

        isMultiple
            ? devisInfosDispatch({ key: 'DeleteMultipleLignes', value: selectedLignes })
            : devisInfosDispatch({ key: 'deleteLigne', value: ligneId });
        isMultiple
            ? selectionManager.dispatch({ key: 'selectedLignes', value: [] })
            : (selectedLignes.includes(ligneId as number)  // On vérifie si elle est deja selected car sinon ca la selectionne
                && selectionManager.dispatch({ key: 'selectedLignes-one', value: ligneId }))

        setShowDelLigneModal(0);
    }

    useEffect(() => {
        if (selectionManager.selectedLigne?.id) document.body.onkeyup = changeSelectedLigne;
        return () => { document.body.onkeyup = null };
    }, [selectionManager.selectedLigne?.id, devisInfos?.lignes.length])
    useEffect(() => {
        document.body.onkeydown = preventArrowScroll;
        return () => { document.body.onkeyup = null };
    }, [])

    useEffect(() => {
        selectionManager.dispatch({ key: 'lignesIds', value: lignesIds })
    }, [devisInfos?.lignes.length, lignesIds?.join(',')])


    // ----- ALGO sur lignes
    const isEndOfLot = devisInfos?.lignes?.map(({ lot_id }, i, arr) => !arr.slice(i + 1).some(obj => obj.lot_id === lot_id) && !isNaN(lot_id));
    let last_titre_type = 0;
    let hide_content = false;
    let activeParentType: number | null = null;

    // ----- BIBLIOTHEQUE BPU
    const [isBibliothequeBpuOpen, setIsBibliothequeBpuOpen] = useState(false);
    const toggleBibliothequeBpu = useCallback(() => setIsBibliothequeBpuOpen(prev => !prev), [setIsBibliothequeBpuOpen]);
    //const [calculatriceData, dispach] = useReducer(CalculatriceReducer, calculatriceDataSetup());

    const [lots, articlesSimple] = useMemo(() => splitArticleAndLot(articles), [articles]);
    const articlesMap = useMemo(() =>
        createMap(articlesSimple, 'id' as keyof ArticleSimple) as { [key: string]: ArticleSimple }, [articlesSimple.length]
    );
    const bibliothequeMetierArticles: BibliothequeMetier = useMemo(() => getListeBpuOuvrage(articlesSimple), [articlesSimple]);
    const bibliothequeMetierOuvrages: BibliothequeMetier = useMemo(() => getListeBpuOuvrage(lots), [lots]);
    const createDevisLignesFromBibliotheque = useCallback((articleIds: number[]) => {
        const articles = articleIds.map(id => articlesMap[id])
        createLignesFromArticles(articles, (devisInfos!.lignes.length + 1) * 1000, devisInfos!.id, devisInfosDispatch);
    }, [articlesMap, devisInfos]);
        
    const articleRSE = devisInfos ? getArticleRSE(devisInfos) : 0;

    return (
        <div className="">
            {/* Nouveau Wrapper */}
            <WrapperBpus
                devisInfos={devisInfos}
                toggleBibliothequeBpu={toggleBibliothequeBpu}
                isBibliothequeBpuOpen={isBibliothequeBpuOpen}
                bibliothequeMetierArticles={bibliothequeMetierArticles}
                bibliothequeMetierOuvrages={bibliothequeMetierOuvrages}
                createDevisLignesFromBibliotheque={createDevisLignesFromBibliotheque}
            />
            {editable ?
                <DevisBodyHeader areMultipleLigneSelected={selectedLignes.length > 0}
                    onSelectionDelete={onSelectionDelete}
                    onSelectionDuplicate={onSelectionDuplicate}
                    onSelectionClose={onSelectionClose}
                    toggleBibliothequeBpu={toggleBibliothequeBpu} />
                :
                <div className="d-md-flex py-2 align-items-center sticky-0 bg-blanc justify-content-between">
                    <div className="d-md-flex align-items-center justify-content-start">
                        <h3 className="m-0 me-3">Articles</h3>
                    </div>
                </div>
            }
            <div className="list-articles">
                <div className="entete-article text-uppercase font-bold g-0 border-bottom display-18  sticky-1">
                    {editable && <div className="colonne-1"></div>}
                    <div className="lh-1 colonne-2"><span>Référence</span></div>
                    <div className="lh-1 colonne-4"><span>Désignation</span></div>
                    <div className="lh-1 colonne-8 text-center"><span>Quantité</span></div>
                    <div className="lh-1 colonne-3 text-center"><span>Unité</span></div>
                    <div className="lh-1 colonne-5 text-end"><span>Prix<br />Unitaire HT</span></div>
                    <div className="lh-1 colonne-6 text-center"><span>Taux<br />TVA</span></div>
                    <div className="lh-1 colonne-7 text-center"><span>Remise</span></div>
                    <div className="lh-1 colonne-9 text-end"><span>TOTAL HT</span></div>
                    {editable && <div className="colonne-10"></div>}
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                    {enabledDroppable &&
                        <Droppable droppableId="devis-list-drop">
                            {(provided) => (

                                <ul className="p-0 m-0 odd" ref={provided.innerRef}>
                                    {devisInfos?.lignes.map((ligne, i, self) => (
                                        <Draggable key={ligne.id} draggableId={ligne.id + ''} index={i} >
                                            {(provided, snapshot) => (
                                                <LigneDevis key={ligne.id}
                                                    devisLigneStart={ligne}
                                                    devisInfosDispatch={devisInfosDispatch}
                                                    articles={filtredArticlesSimple}
                                                    notaList={notaList}
                                                    devisEditable={editable}
                                                    devisId={devisInfos.id}
                                                    TVAListe={TVAListe}
                                                    provided={provided}
                                                    snapshot={snapshot}
                                                    lignesInfos={{
                                                        index: i,
                                                        total: self.length,
                                                        isEndOfLot: isEndOfLot?.[i] || false,
                                                        isInLot: isLotContentAfter(self, i),
                                                        isHidden: isContentHidden(ligne, i, self.length),
                                                    }}
                                                    setShowModal={openDelLigneModal}
                                                    manageSafeExit={manageSafeExit}
                                                    selectArticlePhoto={selectArticlePhoto}
                                                    selectionManager={selectionManager}
                                                    openSelectLotContentModal={openSelectLotContentModal}
                                                    selectLinesAndChildren={selectLinesAndChildren}
                                                />
                                            )}
                                        </Draggable>
                                    ))}

                                    <div>{provided.placeholder}</div>

                                    {editable &&
                                        <LigneDevis key={devisInfos?.lignes.length}
                                            devisInfosDispatch={devisInfosDispatch}
                                            manageSafeExit={manageSafeExit}
                                            articles={filtredArticlesSimple}
                                            lots={filtredLots}
                                            notaList={notaList}
                                            devisEditable
                                            devisLigneStart={emptyDevisLigne}
                                            devisId={devisInfos?.id ?? -1}
                                            TVAListe={TVAListe}
                                            selectionManager={selectionManager}
                                            openSelectLotContentModal={openSelectLotContentModal}
                                            selectLinesAndChildren={selectLinesAndChildren}
                                        />}
                                </ul>
                            )}
                        </Droppable>}
                </DragDropContext>
            </div>

            <div className="small border-top p-2">
                {devisInfos?.lignes.length || 0} ligne{devisInfos && devisInfos?.lignes.length > 1 ? 's' : ''}
            </div>
            <div className="list-articles totaux w-100 bg-gris3 py-2 font-bold  px-2 px-md-0">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="colonne-1 border-0 d-none d-md-block"> </div>
                    <div className="colonne-2 border-0 d-none d-md-block"> </div>
                    <div className="colonne-4 border-0 d-none d-md-block"> </div>
                    <div className="colonne-6 border-0 d-none d-md-block"> </div>
                    <div className="colonne-6 border-0 d-none d-md-block"> </div>
                    <div className="colonne-9 border-0">Article RSE</div>
                    <div className="colonne-8 border-0">1%</div>
                    <div className="colonne-9 border-0">{formatMontant(articleRSE)}</div>
                    {editable && <div className="colonne-10 border-0 d-none d-md-block"> </div>}
                </div>
            </div>
            <DevisBodyTotaux devisInfos={devisInfos} devisInfosDispatch={devisInfosDispatch} editable={editable} />

            <div className="w-100 d-flex align-items-end justify-content-end pt-2 pb-3">
                <div className="d-block">
                    <span className="d-block text-end font-bold">Taux horaire retenu : <span>{formatMontant(devisInfos?.taux_horaire)}</span></span>
                    <span className="d-block bleu-actif font-bold">Budget heures retenu : <span>{devisInfos?.budget_heure.toString().replace('.', ',')}</span> h</span>
                </div>
                {editable &&
                    <div className="d-block">
                        <button className="btn btn-primary btn-rounded bg-bleu-actif blanc d-flex align-items-end justify-content-center ms-2"
                            onClick={openCalculatrice} >
                            <span className="fa fa-calculator"></span>
                        </button>
                    </div>
                }
            </div>
            {editable &&
                <div className="list-articles">
                    <h6 className="border-bottom mb-1">Ajout d'un nota général</h6>
                    <ul className="p-0 m-0 odd border-0">
                        <li className="ligne-article border-0 align-items-center">
                            <div className="colonne-13 d-md-flex">
                                <div className="me-0 me-md-2 mb-1 mb-md-0">
                                    <input type="search" className="form-control search-input" placeholder="Référence du nota" value="" onChange={() => {}}/>
                                </div>
                                <div className="w-100 mb-1 mb-md-0">
                                    <input type="text" className="form-control" placeholder="Votre nota ici !" value="" onChange={() => {}}/>
                                </div>
                            </div>
                            <div className="colonne-10 justify-content-center">
                                <button className="nobt fa fa-ban display-14 bleu mx-1">
                                    <span className="small d-block d-sm-none mx-1">Annuler</span>
                                </button>
                                <button className="nobt fa fa-circle-check display-14 m-1 gris">
                                    <span className="small d-block d-sm-none m-1">Valider</span>
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>}

            <ConfirmModal show={!!showDelLigneModal}
                title={'Attention'}
                message={getDelModalText()}
                onConfirm={handleModalConfirm}
                onCancel={handleModalCancel}
                isRed />
            {useMemo(() => (
                <SelectLotContentModal show={showSelectLotContentModal.isOpen}
                    lot={showSelectLotContentModal.lot}
                    saveEditLot={showSelectLotContentModal.saveEditLot}
                    closeModal={closeSelectLotContentModal}
                />
            ), [showSelectLotContentModal])}
            <PhotoModal isLocalImage photoModalArticle={photoModalArticle} selectArticlePhoto={selectArticlePhoto} />
        </div>
    )

    function onDragEnd(DropResult: DropResult) {
        if (!devisInfos || !DropResult.destination || !editable) return;
        devisInfosDispatch({
            key: 'moveLigne',
            value: [DropResult.source.index, DropResult.destination.index, selectionManager]
        })
    }

    function getDelModalText() {
        return `Vous allez supprimer définitivement ${showDelLigneModal === selectionMultipleDel ? 'ces lignes' : 'cette ligne'} du devis.`
    }
    function onSelectionDelete() {
        setShowDelLigneModal(selectedLignes.length > 1 ? selectionMultipleDel : selectedLignes[0]);
    }
    function onSelectionDuplicate() {
        const selectedLignes = devisInfos?.lignes.filter(l => selectionManager.selectedLignes.includes(l.id));
        if (!selectedLignes || !selectedLignes.length || !devisInfos) return;
        duplicateLignes(selectedLignes, devisInfos.id, devisInfosDispatch);
    }
    function onSelectionClose() {
        selectionManager.dispatch({ key: 'selectedLignes', value: [] })
    }

    function isLotContentAfter(lignes: DevisLigneBaseTypes[], i: number): boolean {
        return lignes[i]?.is_lot_content || (lignes[i + 1] && !lignes[i + 1]?.is_lot && isLotContentAfter(lignes, i + 1))
    }

    function isContentHidden(ligne: DevisLigneBaseTypes, i: number, length: number) {
        if (ligne.is_titre && ligne.titre.type <= last_titre_type) {
            last_titre_type = ligne.titre.type;
            hide_content = false;  // reset hide on new title
            activeParentType = null; // reset active parent on new title
        }

        const mustHide = hide_content;

        if (ligne.is_titre && ligne.titre.hide_content) {
            // hide content if it's a child or if the parent is clicked
            hide_content = true;
            if (activeParentType !== null && ligne.titre.type > activeParentType) {
                // hide child titles if an active parent title exists and the current title is a child
                return true;
            }
            last_titre_type = ligne.titre.type;
            activeParentType = ligne.titre.type;
        }

        if (activeParentType !== null && ligne.is_titre && ligne.titre.type > activeParentType) {
            // hide child titles if an active parent title exists and the current title is a child
            return true;
        }
        return mustHide;
    }

    function selectLinesAndChildren(startLine: DevisLigneEdit): number[] {

        let selectedLineIds: number[] = [];
        const startTitreType = startLine.titre.type;

        for (const ligne of devisInfos?.lignes || []) {

            if (ligne.id === startLine.id) {
                selectedLineIds.push(ligne.id);
            } else if (ligne.is_titre && ligne.titre.type <= startTitreType && selectedLineIds.length > 0) {
                break;
            } else if (selectedLineIds.length > 0) {
                selectedLineIds.push(ligne.id);
            }
        }

        return selectedLineIds;
    }

    function changeSelectedLigne(e: KeyboardEvent) {

        const devisLignes = devisInfos?.lignes;
        const allowedKeys = ['ArrowDown', 'ArrowUp'];
        const selectionId = selectionManager?.selectedLigne?.id;
        if (!selectionManager.selectedLigne || !devisLignes || !allowedKeys.includes(e.key)
            || (e.key === 'ArrowDown' && selectionId === -1)) return;

        const selectedLigneIndex = findSelectedLigneIndex(devisLignes, selectionId ? [selectionId] : []);

        const newSelectedLigneIndex = selectionId === -1
            ? devisLignes.length - 1
            : e.key === 'ArrowDown' ? selectedLigneIndex + 1 : selectedLigneIndex - 1;

        if (newSelectedLigneIndex < 0) return;
        const newSelectedLigne = newSelectedLigneIndex >= devisLignes.length
            ? { id: -1 }
            : devisLignes[newSelectedLigneIndex];
        selectionManager.dispatch({ key: 'selectedLigne', value: newSelectedLigne })
    }

    function changeSelectedLigneAfterDelete(ligneIds: number[]) {
        const devisLignes = devisInfos?.lignes;
        if (!devisLignes || !ligneIds.length
            || !selectionManager.selectedLigne || !ligneIds.includes(selectionManager.selectedLigne?.id)
        ) return;

        const deletedLigneIndex = findSelectedLigneIndex(devisLignes, ligneIds)
        const newSelectedLigne = devisLignes[deletedLigneIndex + 1] || devisLignes[deletedLigneIndex - 1];

        selectionManager.dispatch({ key: 'selectedLigne', value: newSelectedLigne })
    }

    function findSelectedLigneIndex(devisLignes: DevisLigneBaseTypes[], ligneIds: number[]) {
        return devisLignes.findLastIndex(l => ligneIds?.includes(l.id));
    }

    function preventArrowScroll(e: KeyboardEvent) {
        const forbiddenKeys = ['ArrowUp', 'ArrowDown'];
        forbiddenKeys.includes(e.key) && e.preventDefault();
    }

    function getListeBpuOuvrage(articles: ArticleOrLot[]) {
        return articles.reduce((acc, bpu): BibliothequeMetier => {
            const { metier, code_bpu, sous_metier } = bpu;
            acc[metier] ??= {};
            acc[metier][code_bpu] ??= {};
            acc[metier][code_bpu][sous_metier] ??= [];
            acc[metier][code_bpu][sous_metier].push(bpu as any);
            return acc;
        }, {} as BibliothequeMetier)
    }

    function filterArticles(articles: ArticleOrLot[] | undefined, devisInfos: DevisInfos | undefined) {
        const isMetierInArticles = articles?.some(a => a.metier === formattedLabelOfDevis(devisInfos?.metier.selection?.label || ''))
        return articles && articles.filter(a =>
            (!devisInfos?.metier.selection?.label || a.metier === formattedLabelOfDevis(devisInfos.metier.selection?.label) // On filtre sur le metier sauf si pas de metier
            || (!isMetierInArticles && a.code_bpu === '*INTERNE')) // Si le metier n'a pas d'articles, on prend tous les articles internes
            && (!devisInfos?.code_bpu || a.code_bpu === formattedLabelOfDevis(devisInfos.code_bpu) || a.code_bpu === '*INTERNE') // On filtre sur le code bpu sauf si pas de code bpu
        );
    }

    function splitArticleAndLot(articles: ArticleOrLot[]) {
        return articles.reduce((
            (acc, a) => {
                acc[a.is_lot ? 0 : 1].push(a as any);
                return acc;
            }
        ), [[], []] as [LotArticles[], ArticleSimple[]])
    }
}

export default DevisBody;

export const getArticleRSE = (devisInfos: DevisInfos) => 
        devisInfos.lignes.map(line => line.is_article ? line.montant_total : 0)
            .reduce((somme, valeur) => somme + valeur, 0) * (1/100);


export function createLignesFromArticles(articles: ArticleSimple[], ordre: number, devisId: number, devisInfosDispatch: Dispatch<ActionDevis>) {
    const newDevisLignes = articles.map((a, i) => {
        const lotArticleContent = createDevisLigne(ordre + (i + 1) * 1000) as DevisLigneArticleBase;
        const newDevisLigne: DevisLigneArticleBase = {
            ...lotArticleContent,
            article_ref: a.ref,
            designation: a.denomination,
            prix_vente: a.prix_vente,
            montant_total: a.prix_vente,
            TVA: a.TVA,
            unite: a.unite,
        }
        return newDevisLigne
    });
    DevisService.setDevisLignes(newDevisLignes, devisId)
        .then(newIds => {
            if (newIds && newIds.length === newDevisLignes.length) {
                const newDevisLignesWithId = newDevisLignes.map((l, i) => ({ ...l, id: newIds[i] }));
                devisInfosDispatch({ key: 'addLignes', value: newDevisLignesWithId });
            }
        });
}


export function duplicateLignes(selectedLignes: DevisLigneBaseTypes[] | DevisLigneEdit[], devisId: number, devisInfosDispatch: Dispatch<ActionDevis>) {
    if (!selectedLignes || !selectedLignes.length) return;

    const newDevisLignes = selectedLignes.map((objet, index, arr) => {
        let nbAfterSuite = objet.ordre;
        const i = +index;

        while (index < arr.length - 1 && arr[index + 1].ordre === nbAfterSuite + 1000) {
            nbAfterSuite += 1000;
            index++;
        }

        return { ...objet, id: -1, ordre: nbAfterSuite + i + 1 };
    });

    DevisService.setDevisLignes(newDevisLignes, devisId)
        .then(newIds => {
            if (!newIds || newIds.length !== newDevisLignes.length) return;
            devisInfosDispatch({
                key: 'duplicateLignes',
                value: newDevisLignes.map((l, i) => ({ ...l, id: newIds[i] }))
            })
        });
}


export function cancelHigherEvent(e: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>, allowArrows?: boolean) {
    const allowedKeys = allowArrows ? ['ArrowUp', 'ArrowDown', 'Enter'] : [];
    if (allowedKeys.includes(e.key)) return;

    e.preventDefault();
    e.stopPropagation();
}

declare global { // devisLignes.findLastIndex n'est pas reconnu en typescript < 5
    interface Array<T> {
        findLastIndex(
            predicate: (value: T, index: number, obj: T[]) => unknown,
            thisArg?: any
        ): number
    }
}

/*
 
type Liste2 = Metier[];
interface Metier {
  key: string;
  code_bpu: {
      key: string;
      sous_metier: {
          key: string;
          bpus: ArticleSimple[] // BPUs of type bpu
          ouvrages: LotArticles[] // BPUs of type Ouvrage
      }[];
  }[];
};
 console.time('myCode');
  const bibliothequeBpuOuvrage:BibliothequeBpuOuvrage = articles.reduce((acc, bpu):BibliothequeBpuOuvrage => {

      // methode 1
      acc = {
          ...acc,
          [bpu.metier]: {
              ...acc[bpu.metier],
              [bpu.code_bpu]: {
                  ...acc[bpu.metier]?.[bpu.code_bpu],
                  [bpu.sous_metier]: [
                      ...(acc[bpu.metier]?.[bpu.code_bpu]?.[bpu.sous_metier] || []),
                      bpu,
                  ],
              },
          },
      }
      return acc;
  }, {} as BibliothequeBpuOuvrage )
  console.timeEnd('myCode');
*/

/*
 
 
console.time('method2');
const listeBpuOuvrage2: Liste2 = articles.reduce((acc, bpu):Liste2 => {
    const metier = acc.find((item) => item.key === bpu.metier) || acc[acc.push({ key: bpu.metier, code_bpu: [] }) - 1];
    const codeBpu = metier.code_bpu.find((item) => item.key === bpu.code_bpu) || metier.code_bpu[metier.code_bpu.push({ key: bpu.code_bpu, sous_metier: [] }) - 1];
    const sousMetier = codeBpu.sous_metier.find((item) => item.key === bpu.sous_metier) || codeBpu.sous_metier[codeBpu.sous_metier.push({ key: bpu.sous_metier, bpus: [], ouvrages: [] }) - 1];
 
    const typeKey = bpu.is_lot ? 'ouvrages' : 'bpus';
    sousMetier[typeKey].push(bpu as any);
    return acc;
}, [] as Liste2);
console.timeEnd('method2');
 
console.time('method3');
const listeBpuOuvrage3: Liste2 = articles.reduce((acc, bpu) => {
    const findKey = (arr:any[], key:string, defaultVal:any) => arr.find(item => item.key === key) || acc[acc.push({ key, ...defaultVal }) - 1];
 
    const metier = findKey(acc, bpu.metier, { code_bpu: [] });
    const codeBpu = findKey(metier.code_bpu, bpu.code_bpu, { sous_metier: [] });
    const sousMetier = findKey(codeBpu.sous_metier, bpu.sous_metier, { bpus: [], ouvrages: [] });
          
    sousMetier[bpu.is_lot ? 'ouvrages' : 'bpus'].push(bpu);
    return acc;
  }, [] as Liste2);
console.timeEnd('method3');
  

console.log('bibliothequeBpuOuvrage',bibliothequeBpuOuvrage, listeBpuOuvrage2, listeBpuOuvrage3, JSON.stringify(listeBpuOuvrage3) === JSON.stringify(listeBpuOuvrage2));
*/