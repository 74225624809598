import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import QuillToolbar, { formats, modules } from "./toolbar-editor";
import ReactQuill from "react-quill";
import { useTemplates } from "../../../hooks/useMail";
import { DevisInfos } from "../../../class/devis";
import { getPdfModelList, pdfModelList } from "../../../options/pdf-export/models/pdf-model-list";
import { Icon } from "../../tools/icon";
import BtnTooltips from "../../tools/btn-tooltips";
import { ShowModalPdf } from "./show-pdf-modal";
import { SelectDestinataire } from "./select-destinataires";
import { SelectEmailModel } from "./select-email-model";
import templateMailService from "../../../services/TemplateMailService";
import { SelectPdf } from "./select-pdf";

export interface SendMailModalProps {
    show: boolean;
    onHide: () => void;
    fonctionnality: '';
    devisInfos?: DevisInfos;
}

export const SendMailModal = ({ show, onHide, devisInfos }: SendMailModalProps) => {

    console.log("devis infos :", devisInfos);


    const pdfList = getPdfModelList(devisInfos);
    console.log("pdfList :", pdfList);

    const [selectedPdfModelId, setSelectedPdfModelId] = useState(pdfList[0]?.id || '');
    const [selectedPdfName, setSelectedPdfName] = useState('');
    const [showModalPdf, setShowModalPdf] = useState(false);
    const [emailModelId, setEmailModelId] = useState<string>('35');
    const [selectValueInput, setSelectValueInput] = useState('');
    const [selectDestinataire, setSelectDestinataire] = useState('');

    const [loading, setLoading] = useState(false);
    const setIsLoading = (isLoading: boolean) => setLoading(isLoading);

    const { montant_total, num_piece } = devisInfos || {};
    const affaire = devisInfos?.affaire;
    const { numero_dossier, nom_client, nom_do, ref_sinistre, nom_expert, ref_expert_cie_assurance, nom_charge_affaire, agence_email, agence_tel } = affaire || {};

    const templateMails = useTemplates() || [];
    const templateOneMail = templateMails.find(template => template.id === Number(emailModelId));

    const destinairePresentInAffaire = affaire
        ? [
            { id: '0', label: 'DOs Compagnie assurance', email: affaire.mail_assu_do },
            { id: '1', label: 'DOs autres', email: affaire.mail_autre_do },
            { id: '2', label: 'DOs expert', email: affaire.mail_expert_do },
            { id: '3', label: 'DOs plateforme', email: affaire.mail_plateforme_do },
        ].filter(dest => dest.email !== null && dest.email !== undefined)
        : [];

    const emailSelectDestinataire = destinairePresentInAffaire.find(dest => dest.label === selectDestinataire)?.email || '';

    const variables = {
        PRJAP__AFFAIRE: numero_dossier,
        CLI__NOM: nom_client,
        MLOG_ADR2TB__NOM___1: nom_do,
        MLOG_PRJAP__MLOG_CIEREF: ref_sinistre,
        MLOG_ADR2TB__NOM___2: nom_expert,
        MLOG_PRJAP__MLOG_CIEEXPERTREF: ref_expert_cie_assurance,
        DTRENT__VERSIONDEVISORIPINO: num_piece,
        DTRENT__VERSIONDEVISNO: num_piece,
        ETS__EMAIL: agence_email,
        VRP__NOM: nom_charge_affaire,
        ETS__TEL: agence_tel,
        CALCUL: montant_total?.toString(),
    };

    const contentMail = replaceVariables(templateOneMail?.content || "", variables);

    const handleAddAttachment = () => {
        if (!selectedPdfModelId) {
            return;
        }
        try {
            const selectedPdfId = typeof selectedPdfModelId === 'string' ? parseInt(selectedPdfModelId) : selectedPdfModelId;
            const selectedPdf = pdfList.find((model) => model.id === selectedPdfId);
            selectedPdf ? setSelectedPdfName(selectedPdf.name) : console.error("Aucun modèle trouvé avec l'ID sélectionné");

        } catch (error) {
            console.log("Erreur lors de l'ajout de la pièce jointe :", error);
        }
    }

    const handleSelectModelPdf = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedPdfModelId(e.currentTarget.value);
        setShowModalPdf(true)
    };

    const handleSelectEmailModelId = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setEmailModelId(e.currentTarget.value);
    };

    const handleSelectValue = (e: React.ChangeEvent<HTMLInputElement>) =>
        setSelectValueInput(e.currentTarget.value);

    const handleSelectDestinataire = (e: React.ChangeEvent<HTMLSelectElement>) =>
        setSelectDestinataire(e.currentTarget.value);

    const generateAndSendPdf = () => {

        const selectedPdfId = typeof selectedPdfModelId === 'string' && parseInt(selectedPdfModelId);
        const selectedPdf = pdfList.find((model) => model.id === selectedPdfId);
        if (!selectedPdf || !templateOneMail) return;
        templateMailService.addMailPdf(selectedPdf).then(() =>
            templateMailService.sendEmailRequest(selectDestinataire !== '' ? emailSelectDestinataire : selectValueInput, setIsLoading, selectedPdf?.name, contentMail,)
        )
        onHide()
    };

    useEffect(() => {
        handleAddAttachment();
    }, [selectedPdfModelId]);


    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton />
            <Modal.Body>
                <div className="d-flex flex-column gap-3">
                    <SelectEmailModel
                        handleSelectEmailModelId={handleSelectEmailModelId}
                        emailModelId={emailModelId}
                        templateMails={templateMails}
                    />
                    <SelectPdf
                        selectedPdfModelId={selectedPdfModelId}
                        handleSelectModelPdf={handleSelectModelPdf}
                        pdfList={pdfList}
                    />
                    <SelectDestinataire
                        affaire={affaire}
                        handleSelectValue={handleSelectValue}
                        selectValueInput={selectValueInput}
                        selectDestinataire={selectDestinataire}
                        handleSelectDestinataire={handleSelectDestinataire}
                        destinairePresentInAffaire={destinairePresentInAffaire}
                    />
                    <div className='editor-container row h-100 g-0'>
                        <div>
                            <div className="pe-1">
                                <QuillToolbar func={[]} />
                                <ReactQuill
                                    theme="snow"
                                    value={contentMail}
                                    modules={modules}
                                    formats={formats}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        {selectedPdfName && (
                            <div className="border border-primary rounded p-2 w-5 text-primary mt-2 d-flex align-items-center">
                                <Icon icon="file-export fa-solid me-2 bleu" /> PDF: {selectedPdfName}
                                <BtnTooltips
                                    tooltiplabel={'Supprimer la pièce jointe'}
                                    buttonstyle={'nobt fa fa-circle-xmark display-14 rouge m-1'}
                                    onClick={() => setSelectedPdfName('')}
                                    contenu={<span className="small d-block d-sm-none mx-1">Supprimer</span>}
                                />
                            </div>
                        )}
                        {/* <div className="mt-2">
                            <label htmlFor="fileInput" className="d-flex align-items-center cursor-pointer">
                                <Icon icon="paperclip fa-solid me-2" />
                                <span>Ajouter des pièces jointes</span>
                            </label>
                            <input
                                type="file"
                                id="fileInput"
                                multiple
                                style={{ display: 'none' }}
                            />

                        </div> */}
                    </div>
                    <div className="pdf-preview">
                        <ShowModalPdf showModalPdf={showModalPdf} pdfList={pdfList} setShowModalPdf={setShowModalPdf} selectedPdfModelId={selectedPdfModelId} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary mx-2" type="button" onClick={generateAndSendPdf}>
                    <span className="hidemobile">Envoyer</span>
                </button>
            </Modal.Footer>
        </Modal >
    );

    function replaceVariables(content: string, variable: { [key: string]: string | undefined }) {
        let modifiedContent = content;

        typeof content === 'string' && (
            Object.entries(variable).forEach(([key, value]) => {
                const regex = new RegExp(`\\[${key}]`, 'g');
                if (value !== undefined) {
                    modifiedContent = modifiedContent.replace(regex, value);
                }
            })
        )

        return modifiedContent;
    };
};