import { Image, StyleSheet, Text, View } from "@react-pdf/renderer"
import { DevisInfos } from "../../../class/devis";
import { PDFTableContainer } from "./pdf-table-container";
import { PDFLogo } from "./pdf-logo";
import { devisPrefix } from "../../../formatters/documentFormatter";

export interface PDFHeaderProps {
    devisInfos: DevisInfos;
    isProForma?: boolean;
    hideAdresse?: boolean;
    devisWithoutSinistre?: boolean;
}

export const PDFHeader = ({ devisInfos, isProForma, hideAdresse, devisWithoutSinistre }: PDFHeaderProps) => {

    const { affaire, num_piece, creation_date, ref } = devisInfos
    const devis_type = devisInfos.devis_type.selection?.code
    const styles = getPdfHeaderStyleLocalNew();
    const num_piece_pdf = devisInfos.is_devis ? devisPrefix + num_piece : num_piece

    return (
        <View>
            <View style={styles.pdfContainerAdress}>
                <View fixed>
                    {/* ⚠️ Mettre le nom de l'agence sur toutes les pages */}
                    <Text style={styles.nomDevis}>{affaire?.nom_agence}</Text>
                    <Text>{affaire?.agence_adr_rue}</Text>
                    <Text>{affaire?.agence_adr_compl1}</Text>
                    <Text>{affaire?.agence_adr_compl2}</Text>
                    <Text>{affaire?.agence_adr_cp} {affaire?.agence_adr_ville}</Text>
                    <Text>{affaire?.agence_adr_loc !== '' ? 'Localisation : ' + affaire?.agence_adr_loc : ''}</Text>
                </View>

                {!hideAdresse && (
                    <View style={styles.adresse}>
                        <Text style={styles.adressName}>{affaire?.nom_client}</Text>
                        <Text>{affaire?.adresse_client.toLocaleLowerCase()}</Text>
                        <Text>{affaire?.code_postal_client} {affaire?.ville_client}</Text>
                        <Text>{affaire?.compl1_client} {affaire?.compl2_client}</Text>
                        <Text>{affaire?.localisation_client && 'Localisation : ' + affaire.localisation_client}</Text>
                    </View>
                )}

            </View>
            <View style={styles.infosAgence}>
                <View style={styles.infosAgenceContainer} >
                    <View style={styles.infosAgenceText}>
                        <Text style={styles.text}>Tél</Text>
                        <Text>{affaire?.agence_tel}</Text>
                    </View>
                    <View style={styles.infosAgenceText}>
                        <Text style={styles.text}>e-mail</Text>
                        <Text>{affaire?.agence_email}</Text>
                    </View>
                    <View style={styles.infosAgenceText}>
                        <Text style={styles.text}>RIB</Text>
                        <Text>{affaire?.agence_rib}</Text>
                    </View>
                </View>
                <View style={styles.numeroDevis}>
                    {isProForma ? <Text>PROFORMA</Text> : <Text>Devis N°</Text>}
                    <Text>{num_piece_pdf} {devis_type}</Text>
                </View>
            </View>
            <PDFTableContainer
                numero_dossier={affaire?.numero_dossier}
                nom_charge_affaire={affaire?.nom_charge_affaire}
                tel_charge_affaire={affaire?.tel_charge_affaire}
                nom_expert={affaire?.nom_expert}
                ref_sinistre={affaire?.ref_sinistre}
                type_sinistre={affaire?.type_sinistre}
                nom_autre={affaire?.nom_autre}
                nom_plateforme={affaire?.nom_plateforme}
                nom_do={affaire?.nom_do}
                date_creation={creation_date}
                ref_devis={ref} tiers={affaire?.tiers}
                nom_client={affaire?.nom_client}
                rue_sinistre={affaire?.rue_sinistre}
                adresse_client={affaire?.adresse_client}
                ville_client={affaire?.ville_client}
                ville_sinistre={affaire?.ville_sinistre}
                code_postal_client={affaire?.code_postal_client}
                cp_sinistre={affaire?.cp_sinistre}
                compl_1_client={affaire?.compl1_client}
                compl_2_client={affaire?.compl2_client}
                loc_client={affaire?.localisation_client}
                compl_1_sinistre={affaire?.compl1_sinistre}
                compl_2_sinistre={affaire?.compl2_sinistre}
                loc_sinistre={affaire?.localisation_sinistre}
                devisWithoutSinistre={devisWithoutSinistre}
            />
            {/*⚠️ Vérifier le champ `Réf. plateforme` */}
            {affaire?.ref_plateforme &&
                <Text style={{ width: '100%', textAlign: 'center', fontSize: 8 }}> Réf. plateforme : {affaire?.ref_plateforme}</Text>
            }
        </View>
    )
};

export const getPdfHeaderStyleLocalNew = () => {
    return StyleSheet.create({

        pdfContainerAdress: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 10,
            paddingBottom: 5
        },
        nomDevis: {
            textTransform: 'uppercase',
            fontWeight: 900,
            fontSize: 12,
        },
        adresse: {
            marginRight: 60,
        },
        adressName: {
            fontWeight: 900,
            fontSize: 10,
        },
        infosAgence: {
            width: '35%',
            fontSize: 8,
            paddingHorizontal: 10,
            display: 'flex',
            flexDirection: 'column'
        },
        infosAgenceContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        infosAgenceText: {
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            justifyContent: 'flex-start',
            color: "#929292",
            fontWeight: 800,
        },
        text: {
            width: '15%',
            color: '#3a3a3a',
            fontWeight: 400
        },
        numeroDevis: {
            fontWeight: 900,
            fontSize: 14,
            display: 'flex',
            flexDirection: 'row',
            gap: 20,
        },
    })
}